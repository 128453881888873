<template>
  <div :class="'index-container' + activeIndex">
    <el-container id="index-main">
      {{ inputValue }}
      <el-header :class="'navs-container' + activeIndex">
        <topNav @changeNav="changeNav"></topNav>
      </el-header>
      <div class="main-container">
        <indexPage v-if="activeIndex == 0"></indexPage>
        <product v-if="activeIndex == 1"></product>
        <amblyopiaTraining v-if="activeIndex == 2"></amblyopiaTraining>
        <knowledgeQA v-if="activeIndex == 3"></knowledgeQA>
        <aboutUs v-if="activeIndex == 4"></aboutUs>
      </div>
      <div class="footer-container">
        <div class="left-container">
          <span class="footer-words">
            <i></i>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018605">沪公网安备
              31011502018605号</a>
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2021034764号-1</a>
          </span>
          <span style="margin-left:24px" class="footer-words">©2022 上海康得视生物科技有限公司</span>
        </div>
        <div class="right-container">
          <a target="_blank" :href="url + '/open/ssej/userPrivacyAgreement'" class="footer-words">用户协议</a>
          <a target="_blank" :href="url + '/open/ssej/userPrivacyAgreement'" class="footer-words">隐私政策</a>
          <a target="_blank" href class="footer-words">友情链接</a>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import indexPage from "../components/index";
import amblyopiaTraining from "../components/amblyopiaTraining.vue"
import product from "../components/product";
import knowledgeQA from "../components/knowledgeQA";
import aboutUs from "../components/aboutUs";
import EventBus from '../../utils/Eventbus'
export default {
  name: "page",

  components: {
    topNav,
    amblyopiaTraining,
    indexPage,
    product,
    knowledgeQA,
    aboutUs,
  },

  props: {},

  data () {
    return {
      activeIndex: 0, //默认选中
      url: this.$store.state.url,
      inputValue: this.$store.state.inputValue,
    };
  },

  watch: {
    //接收父组件传入的表单数据
    inputValue: {
      immediate: true,
      deep: true,
      handler (val) {
        // console.log(val);
      },
    },

  },
  mounted () {
    EventBus.$on('jumpages', (val) => {
      this.activeIndex = val
    })
  },
  beforeDestroy() {
    EventBus.$off('jumpages')
  },
  methods: {
    changeNav (index) {
      this.activeIndex = index;
    },
    /**
     * 左侧按钮点击事件
     */
    munuSelect (key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style scoped>
#index-main {
  height: 100%;
  display: block;
}

.index-container {
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
}

.index-container0 {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-image: url("../assets/index-bg.png");
}

.index-container3 {
  overflow-x: hidden;
}

.navs-container1 {
  height: 3.34rem !important;
  background-size: 100% 100%;
  background-image: url("../assets/araiingTop.png");
  background-position: center;
}

.navs-container2 {
  height: 3.34rem !important;
  background-size: 100% 100%;
  background-image: url("../assets/product-bg.png");
  background-position: center;
}

.navs-container3 {
  height: 3.34rem !important;
  background-size: 100% 100%;
  background-image: url("../assets/knowledgeQA-bg.png");
  background-position: center;
}

.navs-container4 {
  height: 3.34rem !important;
  background-size: 100% 100%;
  background-image: url("../assets/aboutus-bg.png");
  background-position: center;
}

.footer-container {
  /* position: fixed;
  width: 100%;
  bottom: 0; */
  /* height: 36px; */
  background: #00205c;
  border-radius: 0px 0px 0px 0px;
  padding: 0 0.9rem;
  box-sizing: border-box;
  font-size: 0;
}

.footer-container>div {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
}

.footer-container .right-container {
  float: right;
}

.left-container i {
  display: inline-block;
  height: 0.12rem;
  width: 0.12rem;
  background-size: 100% 100%;
  background-image: url("../assets/footer-icon.png");
}

.footer-words {
  display: inline-block;
  line-height: 0.36rem;
  font-size: 0.12rem;
  color: #fff;
}

.right-container .footer-words {
  margin-right: 0.2rem;
  text-decoration: none;
}

.footer-words a {
  display: inline-block;
  height: 0.36rem;
  line-height: 0.36rem;
  font-size: 0.12rem;
  color: #fff;
  text-decoration: none;
  margin-left: 0.1rem;
}

.right-container .footer-words:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 768px) {
  #index-main .main-container {
    min-height: 100%;
  }
}

/* 移动端 */
@media only screen and (max-width: 1200px) {
  .main-container {
    /* min-height: 13.8rem; */
  }

  .index-main .left-main {
    width: auto;
  }

  .right-main .title-words {
    width: auto;
  }

  .footer-container {
    padding: 0 0.4rem;
    margin-top: 0;
  }

  .footer-container::after {
    clear: both;
    content: "";
    display: block;
  }

  .footer-container .footer-words {
    display: block;
    text-align: center;
    margin-left: 0 !important;
  }

  .footer-container .right-container {
    display: block;
    width: 100%;
    text-align: center;
  }

  .footer-container .right-container a {
    display: inline-block;
  }

  .navs-container1 {
    background-size: auto 100%;
  }

  .navs-container2 {
    background-size: auto 100%;
  }

  .navs-container3 {
    background-size: auto 100%;
  }

  .navs-container4 {
    background-size: auto 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .main-container {
    min-height: 14.8rem;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1280px) {
  .main-container {
    min-height: 12.8rem !important;
  }
}

</style>