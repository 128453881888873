import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// 声明一个常量
const state = {
    // url: "https://1.117.194.163:8000/amblyopia-therapy", //开发环境
    url: "https://www.kds-eyes.com/amblyopia-therapy", //生产环境
    inputValue: "",
    nickName: "",
    token:"",
    pagenumber:''
};
const getters = {
    getinputValue: state => state.inputValue,
    getnickName: state => state.nickName,
};

const mutations = {
        setinputValue(state, inputValue) {
            // console.log(inputValue)
            state.inputValue = inputValue
        },
        setnickName(state, inputValue) {
            // console.log(inputValue)
            state.nickName = inputValue
        },
        setToken(state, inputValue) {
            // console.log(inputValue)
            state.nickName = inputValue
        },
        JIA(state,value){
            // console.log('mutations中的JIA被调用了')
            this.state.pagenumber = value
        },
    }
    const actions = {
        jumpage(context,value){
            // console.log('actions中的jiaWait被调用了')
            context.commit('JIA',value)
        }
    }

    // 外面使用需要进行暴露
export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})