<template>
  <div>
    <div class="navs-container"></div>
    <div class="backUpPage" @click="goBack"></div>
    <div class="logoBox"></div>
    <div class="immediatelymod">
      <div class="immediateText">立即匹配视力类型</div>
    </div>
    <div class="bacilli">
      <!-- 弱视训练表单 -->
      <div class="bacilliformisStyleFrom">
        <div class="baisStyleFrom">
          <div class="baisStyleFromTitle">视力信息:</div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="bacilliForm"
            label-width="1rem"
            :label-position="'top'"
            style="margin-top: 0.35rem"
          >
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="弱视类型选择(单选)："
              prop="amblyopiaType"
            >
              <el-radio-group
                class="el-radio-group"
                v-model="ruleForm.amblyopiaType"
              >
                <el-radio :label="'MYOPIA'">近视型弱视</el-radio>
                <el-radio :label="'HYOEROPIA'">远视型弱视</el-radio>
                <el-radio :label="'ASTUGNATISM'">散光型弱视</el-radio>
                <el-radio :label="'OTHER'">其他型弱视</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="左右眼矫正视力是否接近或度数相差较大(单选):"
              prop="eyeDiffType"
            >
              <el-radio-group
                class="el-radio-group"
                v-model="ruleForm.eyeDiffType"
              >
                <el-radio class="long-radio" :label="'NEAR_LT_2'"
                  >接近: 左右眼矫正视力相差2行视标以内</el-radio
                >
                <el-radio class="long-radio" :label="'DIFF_GT_2'"
                  >不一样: 左右眼矫正视力相差2行视标及以上</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="双眼是否斜视(单选):"
              class="el-form--label-top el-form-item__label"
              prop="strabismusType"
            >
              <el-radio-group v-model="ruleForm.strabismusType">
                <el-radio :label="'LEFT_RIGHT_NONE'">左右眼都没有</el-radio>
                <el-radio :label="'LEFT_RIGHT_BOTH'">左右眼都斜视</el-radio>
                <el-radio :label="'ONLY_RIGHT'">仅右眼斜视</el-radio>
                <el-radio :label="'ONLY_LEFT'">仅左眼斜视</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="其他视力问题(可多选):"
              prop="otherProblem"
            >
              <!-- <el-radio-group
                class="problemsEncephalitis"
                v-model="ruleForm.otherProblem"
              >
                <el-radio :label="'CATARACT'">白内障</el-radio>
                <el-radio :label="'COLOR_WEAKNESS'">色弱</el-radio>
                <el-radio :label="'PARACENTRIC_GAZE'">旁中心注视</el-radio>
                <el-radio :label="'NYSTAGNUS'">眼颤</el-radio>
                <el-radio :label="'COLO_BLINDNESS'">色盲</el-radio>
                <el-radio :label="'FUNDUS_LESIONS'">眼底病变</el-radio>
                <el-radio :label="''">无</el-radio>
              </el-radio-group> -->
              <el-checkbox-group v-model="ruleForm.otherProblem">
                <el-checkbox :label="'CATARACT'">白内障</el-checkbox>
                <el-checkbox :label="'COLOR_WEAKNESS'">色弱</el-checkbox>
                <el-checkbox :label="'PARACENTRIC_GAZE'"
                  >旁中心注视</el-checkbox
                >
                <el-checkbox :label="'NYSTAGNUS'">眼颤</el-checkbox>
                <el-checkbox :label="'COLO_BLINDNESS'">色盲</el-checkbox>
                <el-checkbox :label="'FUNDUS_LESIONS'">眼底病变</el-checkbox>
                <!-- <el-checkbox :label="''">无</el-checkbox>-->
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="optBasicAccessFrom">
        <div class="baisStyleFrom">
          <div class="baisStyleFromTitle">基础信息:</div>
          <el-form
            :model="optBasicGradAccessFrom"
            :rules="rules"
            ref="baisStyleFrom"
            label-width="1rem"
            :label-position="'top'"
            style="margin-top: 0.25rem"
          >
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="姓名"
              prop="name"
            >
              <el-input
                style="width: 2.9rem"
                v-model="optBasicGradAccessFrom.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="性别"
              prop="sex"
            >
              <el-select
                style="width: 2.9rem"
                v-model="optBasicGradAccessFrom.sex"
                placeholder="请选择性别"
              >
                <el-option label="男" value="1" />
                <el-option label="女" value="2" />
              </el-select>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="出生日期"
              prop="birthDay"
            >
              <el-date-picker
                style="width: 2.9rem"
                v-model="optBasicGradAccessFrom.birthDay"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="jsrq"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="年龄"
              prop="type"
            >
              <el-input
                style="width: 2.9rem"
                v-model="optBasicGradAccessFrom.age"
                placeholder="系统自动换算"
                readonly
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="submitForm" @click="submitForm">提交</div>
    </div>
    <div class="footer-containers">
      <div class="left-container">
        <span class="footer-words">
          <i></i>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018605"
            >沪公网安备 31011502018605号</a
          >
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
            >沪ICP备2021034764号-1</a
          >
        </span>
        <span style="margin-left: 24px" class="footer-words"
          >©2022 上海康得视生物科技有限公司</span
        >
      </div>
      <div class="right-containers">
        <a
          target="_blank"
          :href="url + '/open/ssej/userPrivacyAgreement'"
          class="footer-words"
          >用户协议</a
        >
        <a
          target="_blank"
          :href="url + '/open/ssej/userPrivacyAgreement'"
          class="footer-words"
          >隐私政策</a
        >
        <a target="_blank" href class="footer-words">友情链接</a>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "../../utils/Eventbus";
export default {
  name: "bacilliformisDetails",
  data() {
    return {
      url: this.$store.state.url,
      ruleForm: {
        amblyopiaType: "",
        eyeDiffType: "",
        strabismusType: "",
        otherProblem: [],
      },
      optBasicGradAccessFrom: {
        name: "",
        sex: "",
        birthDay: "",
        age: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "change" }],
        sex: [{ required: true, message: "请输入性别", trigger: "change" }],
        birthDay: [
          {
            type: "string",
            required: true,
            message: "请选择出生日期",
            trigger: "change",
          },
        ],
        amblyopiaType: [
          { required: true, message: "请选择弱视类型", trigger: "change" },
        ],
        eyeDiffType: [
          {
            required: true,
            message: "请选择左右眼差别类型",
            trigger: "change",
          },
        ],
        strabismusType: [
          { required: true, message: "请选择斜视类型", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    jsrq() {
      let birthdays = new Date(
        this.optBasicGradAccessFrom.birthDay.replace(/-/g, "/")
      );
      // console.log("生日转换时间", birthdays);
      let d = new Date();
      let age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      this.optBasicGradAccessFrom.age = age;
    },
    submitForm() {
      this.$refs["bacilliForm"].validate((valid) => {
        if (valid) {
          const token = localStorage.getItem("auth");
          if (!token || token === undefined || token === "" || token === null) {
            this.$router.push({
              path: "/",
              name: "login",
            });
            this.$message.warning("请先登录！！！");
            return;
          }
          const otherProblem = this.ruleForm.otherProblem.toString();
          let params = {
            // auth: token,
            sourceChannel: "guanwang",
            sourcePlatform: "web",
            dataSource: "guanwang",
            clientPlatform: "web", // 固定
            name: this.optBasicGradAccessFrom.name,
            sex: this.optBasicGradAccessFrom.sex,
            birthDay: this.optBasicGradAccessFrom.birthDay,
            amblyopiaType: this.ruleForm.amblyopiaType,
            eyeDiffType: this.ruleForm.eyeDiffType,
            strabismusType: this.ruleForm.strabismusType,
            otherProblem: otherProblem,
          };
          this.$axios({
            url: "app/v1.0/userSetmeal/match", //请求地址
            headers: {
              // 设置请求头
              auth: token,
            },
            method: "post", //请求方法
            data: params,
          }).then((res) => {
            if (res.data.code == 1) {
              this.$message.success("提交成功");
            } else {
              this.$message.warning(res.data.message);
            }
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    goBack() {
      // EventBus.$emit('jumpages', 1)
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.navs-container {
  height: 1.5rem !important;
  background-size: 190% 100%;
  background-image: url("../assets/araiingTop.png");
  /* background-position: center; */
}
.backUpPage {
  cursor: pointer;
  position: absolute;
  left: 0.2rem;
  top: 0.35rem;
  width: 0.28rem;
  height: 0.24rem;
  background-image: url("../assets/icon_back.png");
  background-size: 100% 100%;
}
.logoBox {
  position: absolute;
  background-image: url("../assets/icon_login_logo_r.png");
  background-size: 100% 100%;
  width: 1.25rem;
  height: 0.4rem;
  top: 0.3rem;
  right: 0.2rem;
}
.immediatelymod {
  width: 100%;
  height: 0.81rem;
  position: absolute;
  top: 0.92rem;
  right: 0rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/title_bg.png");
  z-index: 99;
}
.immediateText {
  font-size: 0.2rem;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  padding-top: 0.2rem;
}
.bacilli {
  width: 100%;
  height: 13.50rem;
  background: #f6f6f7;
  box-shadow: 0rem 0rem 0.06rem 0.01rem rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
  opacity: 1;
  position: absolute;
  top: 1.32rem;
  right: 0rem;
}
.bacilliformisStyleFrom {
  margin-top: 0.64rem;
  margin-left: 0.24rem;
  width: 3.36rem;
  height: 6.94rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 0.03rem 0.01rem rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
}
.baisStyleFrom {
  margin-left: 0.23rem;
}
.baisStyleFromTitle {
  font-size: 0.18rem;
  position: relative;
  top: 0.28rem;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #00205c;
}

.optBasicAccessFrom {
  width: 3.36rem;
  height: 4.7rem;
  margin-left: 0.24rem;
  margin-top: 0.27rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 0.03rem 0.01rem rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
}
.submitForm {
  width: 3.36rem;
  height: 0.4rem;
  margin-left: 0.24rem;
  margin-top: 0.24rem;
  background: #5189e9;
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
  font-size: 0.16rem;
  text-align: center;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4rem;
  cursor: pointer;
}
.el-form-item__content {
  display: flex;
  color: #000000;
}
.el-form-item__content .el-radio {
  width: 50%;
  margin-right: 0;
  padding-bottom: 0.2rem;
  font-weight: 100 !important;
  color: #565656;
}
.el-form-item__content .long-radio {
  width: 100%;
}

.el-form--label-top .el-form-item__label {
  vertical-align: none !important;
  display: block !important;
  margin-bottom: 0.1rem !important;
  padding: 0rem 0 0 0 !important;
}
.el-radio-button__inner,
.el-radio-group {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  align-content: space-between !important;
  /* height: 0.5rem; */
}
.footer-containers {
  background: #00205c;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  font-size: 0;
  padding: 0 0.4rem;
  margin-top: 0;
  width: 3.75rem;
  position: absolute;
  bottom: -9.7rem;
}

.footer-containers > div {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
}

.footer-containers .right-containers {
  float: right;
}

.left-container i {
  display: inline-block;
  height: 0.12rem;
  width: 0.12rem;
  background-size: 100% 100%;
  background-image: url("../assets/footer-icon.png");
}

.footer-words {
  display: inline-block;
  line-height: 0.36rem;
  font-size: 0.12rem;
  color: #fff;
}

.right-containers .footer-words {
  margin-right: 0.2rem;
  text-decoration: none;
}

.footer-words a {
  display: inline-block;
  height: 0.36rem;
  line-height: 0.36rem;
  font-size: 0.12rem;
  color: #fff;
  text-decoration: none;
  margin-left: 0.1rem;
}

.right-containers .footer-words:last-child {
  margin-right: 0;
}
.footer-containers::after {
  clear: both;
  content: "";
  display: block;
}

.footer-containers .footer-words {
  display: block;
  text-align: center;
  margin-left: 0 !important;
}

.footer-containers .right-containers {
  display: block;
  width: 100%;
  text-align: center;
}

.footer-containers .right-containers a {
  display: inline-block;
}
</style>
