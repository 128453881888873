<template>
  <div class="regist-container">
    <div class="regist-main">
      <div class="block-common left-logo">
        <i class="logo-img"></i>
      </div>
      <div class="block-common right-container">
        <div class="right-main">
          <div class="corner" @click="jumpLogin()">登录</div>
          <div class="common-title">账号注册</div>
          <el-form
            class="regist-form"
            status-icon
            :rules="loginRules"
            ref="loginForm"
            :model="loginForm"
            label-width="0"
          >
            <el-form-item prop="mobile" class="mobile-item">
              <el-input
                v-model="loginForm.mobile"
                auto-complete="off"
                placeholder="请输入手机号"
              >
                <span slot="prefix" class="icon-left">
                  <i class="icon-phone" />
                  <span>+86</span>
                </span>
              </el-input>
            </el-form-item>
            <el-form-item class="code-item" prop="smsVerifyCode">
              <el-row :span="24">
                <el-col :span="16">
                  <el-input
                    size="small"
                    v-model="loginForm.smsVerifyCode"
                    auto-complete="off"
                    placeholder="请输入验证码"
                  >
                    <i slot="prefix" class="icon-code" />
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <el-button type="text" @click="sendCode"
                    >发送验证码</el-button
                  >
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                auto-complete="off"
                :show-password="true"
                placeholder="请输入设置密码"
              >
                <i slot="prefix" class="icon-mima" />
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input
                v-model="loginForm.confirmPassword"
                :show-password="true"
                auto-complete="off"
                placeholder="请再次输入密码"
              >
                <i slot="prefix" class="icon-mima" />
              </el-input>
            </el-form-item>
            <el-form-item prop="isAgreeUserAgreement">
              <el-checkbox
                v-model="loginForm.isAgreeUserAgreement"
                @change="changeRemember"
              >
                我已阅读并同意
                <span style="color: #5189e9">服务条款，隐私政策</span>
              </el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click.native.prevent="handleRegist"
                class="login-submit"
                >注册</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page",
  components: {},
  props: {},
  data() {
    return {
      logoUrl: require("../assets/logo.png"),
      passwordType: "password",
      loginForm: {
        mobile: "" ,
        smsVerifyCode: "", //填入的
        password: "",
        confirmPassword: "",
        isAgreeUserAgreement: false,
      },
      loginRules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
      devUrl: "https://1.117.194.163:8000/amblyopia-therapy",
    };
  },
  // watch: {
  //   // 监听路由变化
  //   router(to, from) {
  //     console.log(to, from);
  //   },
  // },
  mounted() {
    // 拿到地址栏的数据
    const pthone = this.$route.query.phone;
    console.log(pthone);
    if (pthone) {
      this.loginForm.mobile = pthone;
    }
  },

  methods: {
    /**
     * 注册
     */
    handleRegist() {
      var isAgreeUserAgreement;
      if (this.loginForm.isAgreeUserAgreement) {
        isAgreeUserAgreement = 1;
      } else {
        isAgreeUserAgreement = 0;
      }
      var obj = {
        sourceChannel: "guanwang",
        sourcePlatform: "web",
        dataSource: "guanwang",
        clientPlatform: "web", // 固定
        mobile: this.loginForm.mobile,
        smsVerifyCode: this.loginForm.smsVerifyCode, //填入的
        password: this.loginForm.password,
        confirmPassword: this.loginForm.confirmPassword,
        isAgreeUserAgreement: isAgreeUserAgreement,
      };
      this.$axios({
        url: "/app/v1.0/user/register", //请求地址
        method: "post", //请求方法
        data: obj,
      }).then((res) => {
        // console.log(res.data);
        if (res.data.code == 1) {
          this.$message.success("注册成功！");
          this.$router.push({
            path: "/login",
            name: "login",
          });
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    /**
     * 发送验证码
     */
    sendCode() {
      this.$axios({
        url: "/app/v1.0/smsCaptcha", //请求地址
        method: "post", //请求方法
        data: {
          sourceChannel: "guanwang",
          sourcePlatform: "web",
          dataSource: "guanwang",
          mobile: this.loginForm.mobile,
          bizType: "REGISTER_GUANWANG",
        },
      }).then((res) => {
        // console.log(res.data);
        if (res.data.code == 1) {
          this.$set(this.loginForm, "smsCode", res.data.data.smsCode);
          // console.log(this.loginForm);
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    /**
     *
     */
    jumpLogin() {
      this.$router.push({
        path: "/login",
        name: "login",
      });
    },
  },
};
</script>

<style>
.regist-container {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("../assets/login-bg.png");
}
.left-logo .logo-img {
  position: absolute;
  display: inline-block;
  width: 2.63rem;
  height: 0.8rem;
  top: 50%;
  margin-top: -0.4rem;
  background-size: 100% 100%;
  background-image: url("../assets/logo.png");
}
.regist-main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
}
.regist-form {
  margin-top: 0.48rem;
}
.regist-main .block-common {
  display: inline-block;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.regist-main .left-logo {
  padding-left: 1.2rem;
}
.regist-main .right-container {
}
.left-logo img {
  position: absolute;
  width: 2.63rem;
  height: 0.8rem;
  top: 50%;
  margin-top: -0.4rem;
}
.regist-container .right-main {
  width: 5.2rem;
  height: 6.3rem;
  background: #ffffff;
  border-radius: 0.16rem;
  opacity: 1;
  position: absolute;
  top: 50%;
  margin-top: -3.15rem;
  right: 1.84rem;
  padding: 0.8rem 0.7rem 0 0.7rem;
  box-sizing: border-box;
}
.right-main .corner {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.88rem;
  height: 0.88rem;
  line-height: 0.68rem;
  text-align: right;
  color: #fff;
  font-size: 0.18rem;
  padding-right: 2px;
  background-size: 100% 100%;
  background-image: url("../assets/regist-bg.png");
}
.right-main .common-title {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
}
.regist-form {
  margin-top: 1.05rem;
}
.icon-yonghu {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.14rem;

  background-size: 100% 100%;
  background-image: url("../assets/user-icon.png");
}
.icon-code {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.14rem;
  background-size: 100% 100%;
  background-image: url("../assets/code-icon.png");
}
.icon-phone {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.14rem;
  background-size: 100% 100%;
  background-image: url("../assets/phone-icon.png");
}
.icon-left span {
  vertical-align: top;
}
.icon-mima {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.14rem;
  background-size: 100% 100%;
  background-image: url("../assets/mima-icon.png");
}
.regist-form .el-input input {
  height: 0.48rem !important;
  padding-left: 0.5rem;
}
.regist-form .el-form-item__content {
  line-height: 0.48rem;
}
.regist-form .el-button {
  height: 0.48rem;
  width: 100%;
}
.code-item {
  border: 1px solid #dcdfe6;
}
.mobile-item .el-input input {
  padding-left: 0.8rem;
}
/* 移动端 */
@media only screen and (max-width: 768px) {
  .regist-container .regist-main .block-common {
    width: 100%;
  }
  .regist-container .regist-main .left-logo {
    padding-left: 0px;
    text-align: center;
    height: 2.4rem;
  }
  .regist-container .regist-main .left-logo .logo-img {
    width: 1.28rem;
    height: 0.4rem;
    margin-left: -0.64rem;
    margin-top: -0.2rem;
    background-image: url("../assets/logo-mb.png");
  }
  .regist-container .regist-main .right-container {
    width: 100%;
    position: relative;
    bottom: 0;
    height: calc(100% - 2.4rem);
  }
  .regist-container .regist-main .right-main {
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem 0px;
    opacity: 1;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
  }
}
</style>