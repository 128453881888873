<template>
  <div class="login-container">
    <div class="login-main">
      <div class="block-common left-logo">
        <i class="logo-img"></i>
      </div>
      <div class="block-common right-container">
        <div class="right-main">
          <div class="corner" @click="jumpRegist()">注册</div>
          <div class="common-title">账号登录</div>
          <el-form
            class="login-form"
            status-icon
            :rules="loginRules"
            ref="loginForm"
            :model="loginForm"
            label-width="0"
          >
            <el-form-item prop="mobile">
              <el-input
                @keyup.enter.native="handleLogin"
                v-model="loginForm.mobile"
                auto-complete="off"
                placeholder
              >
                <i slot="prefix" class="icon-yonghu" />
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                @keyup.enter.native="handleLogin"
                :type="passwordType"
                v-model="loginForm.password"
                :show-password="true"
                auto-complete="off"
                placeholder
              >
                <!-- <i class="el-icon-view el-input__icon" slot="suffix"  />
                <i slot="prefix" class="icon-mima" />-->
                <i slot="prefix" class="icon-mima" />
              </el-input>
            </el-form-item>
            <el-form-item prop="rememberPassword">
              <el-checkbox v-model="loginForm.rememberPassword" @change="changeRemember">记住密码</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click.native.prevent="handleLogin"
                class="login-submit"
              >登录</el-button>
            </el-form-item>
          </el-form>
          <div class="forget-container">
            <span @click="forgetPassword">忘记密码</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page",

  components: {},

  props: {},

  data() {
    return {
      logoUrl: require("../assets/logo.png"),
      passwordType: "password",
      loginForm: {
        mobile: "",
        password: "",
        rememberPassword: false,
      },
      loginRules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    };
  },

  methods: {
    /**
     * 登录
     */
    handleLogin() {
      var isAgreeUserAgreement;
      if (this.loginForm.isAgreeUserAgreement) {
        isAgreeUserAgreement = 1;
      } else {
        isAgreeUserAgreement = 0;
      }
      var obj = {
        sourceChannel: "guanwang",
        sourcePlatform: "web",
        dataSource: "guanwang",
        clientPlatform: "web", // 固定
        loginMode: 1, //登录方式（1.帐号密码，2.短信验证码）
        mobile: this.loginForm.mobile,
        password: this.loginForm.password,
      };
      this.$axios({
        url: "/app/v1.0/user/login", //请求地址
        method: "post", //请求方法
        data: obj,
      }).then((res) => {
        // console.log(res.data);
        if (res.data.code == 1) {
          // this.$store.commit("setnickName", res.data.data.nickname);
          localStorage.setItem("ssjenickName",res.data.data.nickname)
          localStorage.setItem('auth',res.data.data.auth)
          this.$router.push({
            path: "/index",
            name: "index",
          });
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    /**
     * 忘记密码
     */
    forgetPassword() {
      this.$router.push({
        path: "/forget",
        name: "forget",
      });
    },

    /**
     * 记住密码
     */
    changeRemember() {
      // console.log(this.loginForm.rememberPassword);
    },
    /**
     *
     */
    jumpRegist() {
      this.$router.push({
        path: "/regist",
        name: "regist",
      });
    },
  },
};
</script>

<style>
.login-container {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("../assets/login-bg.png");
}
.login-main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
}
.login-main .block-common {
  display: inline-block;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.login-main .left-logo {
  padding-left: 1.2rem;
}
.login-main .right-container {
}
.left-logo .logo-img {
  position: absolute;
  display: inline-block;
  width: 2.63rem;
  height: 0.8rem;
  top: 50%;
  margin-top: -0.4rem;
  background-size: 100% 100%;
  background-image: url("../assets/logo.png");
}
.login-container .right-main {
  width: 5.2rem;
  height: 6.3rem;
  background: #ffffff;
  border-radius: 0.16rem;
  opacity: 1;
  position: absolute;
  top: 50%;
  margin-top: -3.15rem;
  right: 1.84rem;
  padding: 0.8rem 0.7rem 0 0.7rem;
  box-sizing: border-box;
}
.right-main .corner {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.88rem;
  height: 0.88rem;
  line-height: 0.68rem;
  text-align: right;
  color: #fff;
  font-size: 0.18rem;
  padding-right: 2px;
  background-size: 100% 100%;
  background-image: url("../assets/regist-bg.png");
}
.right-main .common-title {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
}
.login-form {
  margin-top: 1.05rem;
}
.icon-yonghu {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.14rem;

  background-size: 100% 100%;
  background-image: url("../assets/user-icon.png");
}
.icon-mima {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.14rem;
  background-size: 100% 100%;
  background-image: url("../assets/mima-icon.png");
}
.login-form .el-input input {
  height: 0.48rem !important;
  padding-left: 0.5rem;
}
.login-form .el-form-item__content {
  line-height: 0.48rem;
}
.login-form .el-button {
  height: 0.48rem;
  width: 100%;
}
.forget-container {
  text-align: right;
}
.forget-container span {
  cursor: pointer;
  font-size: 0.14rem;
  font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
  font-weight: 400;
  color: #677685;
}
/* 移动端 */
@media only screen and (max-width: 768px) {
  .login-container .login-main .block-common {
    width: 100%;
  }
  .login-container .login-main .left-logo {
    padding-left: 0px;
    text-align: center;
    height: 2.4rem;
  }
  .login-container .login-main .left-logo .logo-img {
    width: 1.28rem;
    height: 0.4rem;
    margin-left: -0.64rem;
    margin-top: -0.2rem;
    background-image: url("../assets/logo-mb.png");
  }
  .login-container .login-main .right-container {
    width: 100%;
    position: relative;
    bottom: 0;
    height: calc(100% - 2.4rem);
  }
  .login-container .login-main .right-main {
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem 0px;
    opacity: 1;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
  }
}
</style>