<template>
  <div id="aboutus-container">
    <div class="top-main">
      <div class="top-main-bg">
        <div class="left-block">
          圣视尔佳弱视训练，是一款辅助弱视训练的软件，通过“眼力”、“脑力”、“心理”和“动手能力”相结合，由看、到动手、到专注、再到思考，结合眼心脑手的一个训练过程。不仅是内容有趣，还结合协调以及益智类游戏。让训练课程和动脑相结合，
          <span>孩子更喜欢！</span>
        </div>
        <div class="right-block">
          <div class="right-block-common left-common">
            <div class="block-common-title">我们的文化</div>
            <div class="block-common-desc">让训练过程更有趣，让孩子训练更开心；<br>让训练过程更自主，让家长陪伴更轻松；</div>
          </div>
          <div class="right-block-common right-common">
            <div class="block-common-title">我们的使命</div>
            <div class="block-common-desc">根据弱视患者的需求，不断打磨产品； <br>为帮助弱视训练为己任，一路前行；</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 地图部分 -->
    <div class="map-container">
      <div class="left-map"></div>
      <div class="right-block">
        <div class="right-title">Address</div>
        <div class="right-title1">我们在哪儿</div>
        <div class="right-line">
          <div class="title">公司地址</div>
          <div class="content">上海市浦东新区川沙路2977号B栋B304</div>
        </div>
        <div class="right-line">
          <div class="title">公司邮箱</div>
          <div class="content">Market@kangdeshi.com</div>
        </div>
        <div class="right-line">
          <div class="title">客服电话</div>
          <div class="content">400-1020-124</div>
        </div>
        <div class="right-line">
          <div class="title">商务电话</div>
          <div class="content">18721845889</div>
        </div>
        <div class="bottom-button">联系我们</div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottom-container">
      <div class="left-logo"></div>
      <div class="right-icons">
        <div class="common-icon">
          <i class="common-icon icon1"></i>
          <img
            class="common-img"
            src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-missBruceLee-QQ.jpg"
          />
        </div>
        <div class="common-icon">
          <i class="common-icon icon2"></i>
          <img
            class="common-img"
            src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-officialAccount.jpg"
          />
        </div>
        <div class="common-icon">
          <i class="common-icon icon3"></i>
          <img
            class="common-img"
            src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-microblog.jpg"
          />
        </div>
        <div class="common-icon">
          <i class="common-icon icon4"></i>
          <img
            class="common-img"
            src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-customerService-Wechat.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    tabShow(index) {
      var flag = this.knowledgeQAList[index].flag;
      this.$set(this.knowledgeQAList[index], "flag", !flag);
    },
  },
};
</script>

<style>
#aboutus-container .top-main {
  /*padding-top: 0.76rem;*/
  /*padding-left: 1.5rem;*/
  padding: 0.97rem 0.5rem 0 1.15rem;
}
.top-main-bg {
  /*width: 12.2rem;*/
  /*height: 4.48rem;*/
  /*width: 9.17rem;*/
  /*height: 3.37rem;*/
  width: 100%;
  padding-top: 0.72rem;
  background-size: 100% 100%;
  background-image: url("../assets/aboutus-bg1.png");
}
.top-main-bg > div {
  display: inline-block;
  vertical-align: top;
}
.top-main-bg .left-block {
  /*width: 4.18rem;*/
  /*font-size: 0.16rem;*/
  width: 3.14rem;
  font-size: 0.12rem;
  font-family: "Source Han Sans CN-Medium, Source Han Sans CN";
  font-weight: 500;
  color: #00205c;
  /*line-height: 0.4rem;*/
  /*padding-left: 0.53rem;*/
  /*padding-top: 0.2rem;*/
  line-height: 0.3rem;
  margin-left: 0.53rem;
  margin-top: 0.28rem;
  box-sizing: border-box;
}
.top-main-bg .right-block {
  /*margin-left: 1.1rem;*/
  margin-left: 1.2rem;
  width: 6rem;
  position: relative;
  /*margin-top: 0.2rem;*/
  height: 4rem;
}
.right-block .right-block-common {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  /*width: 3.36rem;*/
  /*height: 1.84rem;*/
  /*padding: 0.4rem 0.25rem;*/
  padding: 0.4rem 0.4rem;
}
.right-block .right-common {
  /*left: 3.1rem;*/
  left: 3.5rem;
  top: 1.6rem;
  /*width: 100%;*/
}
.top-main-bg .left-block span {
  color: #00be99;
}
.block-common-title {
  /*font-size: 0.2rem;*/
  font-size: 0.15rem;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  font-weight: bold;
  color: #5189e9;
  line-height: 0.16rem;
  margin-bottom: 0.2rem;
}
.block-common-desc {
  /*font-size: 0.15rem;*/
  font-size: 0.11rem;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  font-weight: 400;
  line-height: 0.26rem;
  color: #565656;
}
/* 地图部分 */
.map-container {
  margin-top: 1.3rem;
}
.map-container > div {
  display: inline-block;
  vertical-align: top;
}
.map-container .left-map {
  width: 9.42rem;
  height: 6.4rem;
  /*width: 7.08rem;*/
  /*height: 4.83rem;*/
  background-size: 100% 100%;
  background-image: url("../assets/map-bg.png");
}
.right-block {
  padding-left: 0.4rem;
}
.right-block .right-title {
  /*font-size: 0.14rem;*/
  font-size: 0.11rem;
  font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
  font-weight: 400;
  color: #00be99;
}
.right-block .right-title1 {
  /*font-size: 0.48rem;*/
  font-size: 0.36rem;
  /*margin-bottom: 1.3rem;*/
  margin-bottom: 1rem;
  font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
  font-weight: bold;
  color: #00205c;
}
.right-block .right-line {
  margin-bottom: 0.3rem;
  /*margin-bottom: 0.15rem;*/
}
.right-line .title {
  /*font-size: 0.14rem;*/
  font-size: 0.11rem;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  font-weight: bold;
  color: #00205c;
}
.right-line .content {
  /*font-size: 0.14rem;*/
  font-size: 0.12rem;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  font-weight: 400;
  color: #565656;
}
.bottom-button {
  display: inline-block;
  /*width: 2.2rem;*/
  /*height: 0.5rem;*/
  width: 1.65rem;
  height: 0.38rem;
  margin-top: 0.3rem;
  /*line-height: 0.5rem;*/
  line-height: 0.4rem;
  border-radius: 0.5rem;
  text-align: center;
  color: #fff;
  background-color: #00be99;
  /*font-size: 0.14rem;*/
  font-size: 0.12rem;
}
/* 底部 */
.bottom-container {
  /*height: 0.94rem;*/
  height: 0.71rem;
  padding: 0 2.08rem 0 1.03rem;
  background-color: #5189e9;
  font-size: 0;
}
.bottom-container > div {
  display: inline-block;
  vertical-align: top;
}
.bottom-container .left-logo {
  /*width: 1.42rem;*/
  /*height: 0.48rem;*/
  width: 1.07rem;
  height: 0.36rem;
  margin-top: 0.23rem;
  background-size: 100% 100%;
  background-image: url("../assets/footer-logo.png");
}
.bottom-container .right-icons {
  float: right;
  margin-top: 0.3rem;
}
.right-icons .common-icon {
  position: relative;
  display: inline-block;
  /*width: 0.28rem;*/
  /*height: 0.28rem;*/
  width: 0.21rem;
  height: 0.21rem;
  background-size: 100% 100%;
  margin-right: 0.08rem;
}
.common-icon .common-img {
  display: none;
  position: absolute;
  bottom: 0.5rem;
  width: 1.92rem;
  /* height: 128px; */
  border-radius: 0.1rem;
}
.common-icon:hover .common-img {
  display: inline-block !important;
}
.right-icons .icon1 {
  background-image: url("../assets/footer-icon1.png");
}
.right-icons .icon2 {
  background-image: url("../assets/footer-icon2.png");
}
.right-icons .icon3 {
  background-image: url("../assets/footer-icon3.png");
}
.right-icons .icon4 {
  background-image: url("../assets/footer-icon4.png");
}

/*超小屏幕*/
@media only screen and (max-width: 768px) {
  #aboutus-container .top-main {
    padding-top: 0;
    padding-left: 0;
  }
  .top-main-bg {
    width: 100%;
    background-size: auto 100%;
    height: auto;
    padding-top: 0.4rem
  }
  .top-main-bg .left-block {
    display: block;
    width: auto;
    padding: 0.1rem 0.2rem;
    margin-bottom: 0.53rem;
  }
  .top-main-bg .right-block {
    display: block;
    width: auto;
    padding: 0.4rem 0.2rem;
    margin-left: 0;
    background-size: 100% auto;
    height: 2.6rem;
    background-image: url("../assets/about-bg3.png");
  }
  .map-container {
    margin-top: 0.4rem;
  }
  .map-container .right-block {
    display: block;
    padding-right: 0.4rem;
  }
  .map-container .left-map {
    width: 100%;
    height: 2.56rem;
  }
  .right-block .right-title {
    margin-top: 0.4rem;
  }
  .right-block .right-title1 {
    margin-bottom: 0.4rem;
  }
  .bottom-button {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  .bottom-container::after {
    clear: both;
    content: "";
    display: block;
  }
  .bottom-container {
    height: auto;
    padding: 0 0.2rem 0.2rem 0.2rem;
    font-size: 0;
  }
  .bottom-container .right-icons {
    display: block;
    width: 100%;
  }
  .right-block .right-block-common {
    /*padding-top: 0.9rem;*/
    padding: 0.5rem 0.1rem;
  }
  .right-block .right-common {
    left: 0.8rem;
    top: 1.27rem;
  }
  .block-common-title {
    font-size: 0.13rem;
    font-weight: bold;
    color: #5189e9;
    line-height: 0.16rem;
    margin-bottom: 0.1rem;
  }
  .block-common-desc {
    font-size: 0.09rem;
    line-height: 0.18rem;
  }
  .bottom-button {
    display: revert;
  }
}

/*小屏幕*/
@media only screen and (min-width: 768px) {
  .right-block .right-common {
    width: 100%;
  }
}
</style>