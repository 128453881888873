<template>
  <div class="index-main">
    <div class="downommon">
      <div class="registrationasss registrs">
        <div class="registrationsacdd">在线弱视训练</div>
      </div>
      <div class="registrationaaawde registrats">
        <div class="regtionawdebox">
          <el-form
            :model="formregtionawde"
            :rules="rules"
            ref="baorm"
            label-width="1rem"
            :label-position="'top'"
            style="margin-top: 0.75rem"
          >
            <el-form-item prop="regtionawde">
              <el-input
                max-length="11"
                placeholder="请输入手机号"
                v-model="formregtionawde.regtionawde"
              >
                <template class="prependBox" slot="prepend">
                  <div class="prepend-icon">
                    <i class="el-icon-tickets" />
                  </div>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="submitFormaaaaa" @click="submitForm">登录注册</div>
        </div>
      </div>
    </div>

    <div v-if="clientWidth <= 1280" class="right-main">
      <div class="top-logo">
        <i class="logo-icon"></i>
      </div>
      <div class="title-words">一款辅助弱视训练的软件</div>
      <div class="button-common">
        <a class>
          <i class="download-common mobile-download">
            <div class="mobile-container">
              <i class="qr-code"></i>
              <span>微信扫一扫 或 截屏长按二维码</span>
            </div>
          </i>
        </a>
        <!-- 如果屏幕宽高为移动端则不显示 -->
        <a
          v-if="clientWidth > 1280"
          href="https://oss-kds-eyes.com/kds_win_install.exe"
          class
        >
          <i class="download-common pc-download"></i>
        </a>
      </div>
    </div>
    <div class="left-main">
      <div class="video-container">
        <video
          src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/GameVideo.mp4"
          poster="../assets/video-bg.png"
          controls
          width="100%"
          height="100%"
          ref="myVideo"
        ></video>
        <i
          @click="changeVideo"
          :class="!playFlag ? 'play-icon' : 'pause-icon'"
        ></i>
      </div>

      <!-- <video src="../assets/video.mp4"></video> -->
    </div>
    <div v-if="clientWidth > 1280" class="right-main">
      <div class="top-logo">
        <i class="logo-icon"></i>
      </div>
      <div class="title-words">一款辅助弱视训练的软件</div>
      <div class="button-common">
        <a class>
          <i class="download-common mobile-download">
            <div class="mobile-container">
              <i class="qr-code"></i>
              <span>微信扫一扫 或 截屏长按二维码</span>
            </div>
          </i>
        </a>
        <!-- 如果屏幕宽高为移动端则不显示 -->
        <a
          v-if="clientWidth > 1280"
          href="https://oss-kds-eyes.com/kds_win_install.exe"
          class
        >
          <i class="download-common pc-download"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      playFlag: false,
      clientWidth: document.body.clientWidth,
      formregtionawde: {
        regtionawde: "",
      },
      rules: {
        regtionawde: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (
                /^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(
                  value
                ) == false
              ) {
                callback(new Error("请输入手机号"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth;
  },
  mounted() {
    if (this.$refs.myVideo) {
      // 监听视频播放
      // this.$refs.myVideo.addEventListener("play", this.handlePlay);
      // // 监听视频暂停
      // this.$refs.myVideo.addEventListener("pause", this.handlePause);
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.clientWidth = window.screenWidth;
      })();
    };
  },
  methods: {
    huoquyemiankuangao() {},
    changeVideo() {
      if (this.playFlag) {
        // console.log(this.playFlag);
        this.handlePause();
      } else {
        this.handlePlay();
      }
      this.playFlag = !this.playFlag;
    },
    handlePlay() {
      // console.log(this.$refs.myVideo);
      this.$refs.myVideo.play();
    },
    handlePause() {
      // console.log(this.$refs.myVideo.paused);
      this.$refs.myVideo.pause();
    },
    submitForm() {
      this.$refs["baorm"].validate((valid) => {
        if (valid) {
          // 跳转到注册页面
          this.$router.push({
            name: "regist",
            path: "/regist",
            query: {
              phone: this.formregtionawde.regtionawde,
            },
          });
        }
      });
    },
  },
};
</script>

<style>
.index-main {
  padding: 0;
  /*padding-top: 0.75rem;*/
  padding-top: 0.5rem;
  padding-bottom: 0.35rem;
}
.rationLogindialog {
  position: absolute;
  top: 0.79rem;
  right: 1.1rem;
}
.index-main > div {
  display: inline-block;
}

.index-main .left-main {
  width: 8.18rem;
  height: 5.83rem;
  /*width: 6.15rem;*/
  /*height: 4.38rem;*/
}

.left-main .video-container {
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  padding-left: 1.2rem;
  padding-top: 0.7rem;
  position: relative;
}

.video-container i {
  position: absolute;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  left: 50%;
  top: 50%;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
  background-size: 100% 100%;
}

.video-container .play-icon {
  /*background-image: url("../assets/play-icon.png");*/
}

.video-container .pause-icon {
  /*background-image: url("../assets/pause-icon.png");*/
}

.index-main .right-main {
  /*padding-top: 0.8rem;*/
  padding-top: 0.6rem;
  vertical-align: top;
  padding-left: 0.38rem;
}

.top-logo .logo-icon {
  display: inline-block;
  /*width: 1.25rem;*/
  /*height: 0.4rem;*/
  width: 1rem;
  height: 0.33rem;
  margin-top: 0.17rem;
  background-size: 100% 100%;
  background-image: url("../assets/index-logo.png");
}

.right-main .title-words {
  /*width: 4.68rem;*/
  /*font-size: 0.56rem;*/
  width: 3.52rem;
  font-size: 0.42rem;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  font-weight: bold;
  color: #ffffff;
  line-height: 0.59rem;
}

.right-main .button-common {
  /*padding-top: 0.6rem;*/
  padding-top: 0.1rem;
}

.right-main .button-common .download-common {
  display: inline-block;
  /*height: 0.56rem;*/
  height: 0.42rem;
  background-size: 100% 100%;
}

.button-common .mobile-download {
  position: relative;
  /*width: 2.41rem;*/
  width: 1.83rem;
  background-image: url("../assets/mobile-download.png");
}

.button-common .pc-download {
  /*width: 1.58rem;*/
  width: 1.19rem;
  margin-left: 0.18rem;
  background-image: url("../assets/pc-download.png");
}

.button-common {
  position: relative;
}

.mobile-container {
  position: absolute;
  display: none;
  top: 0.6rem;
  left: 0;
  width: 2.44rem;
  height: 1.3rem;
  background: #ffffff;
  border-radius: 0.16rem;
  padding: 0.16rem;
  box-sizing: border-box;
  z-index: 100;
}

.mobile-download:hover .mobile-container {
  display: block !important;
}

.mobile-container .qr-code {
  display: inline-block;
  vertical-align: top;
  width: 0.98rem;
  height: 0.98rem;
  background-size: 100% 100%;
  background-image: url("../assets/qr-code.png");
}

.mobile-container span {
  display: inline-block;
  vertical-align: top;
  padding: 0.3rem 0;
  /*width: 1.1rem;*/
  width: 1.01rem;
  padding-left: 0.2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.12rem;
  font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
  font-weight: 400;
  color: #565656;
  /*line-height: 0.16rem;*/
  line-height: 0.18rem;
}

video::-webkit-media-controls-play-button {
  width: 40px;
  height: 40px;
  font-size: 50px;
}

video::-webkit-media-controls-timeline {
  height: 10px;
}

.registrationasss {
  width: 2.29rem;
  height: 0.81rem;
  position: absolute;
  top: 0.5rem;
  right: 1.3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/registrationLogindialog.png");
  z-index: 99;
}
.registrationsacdd {
  font-size: 0.2rem;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  padding-top: 0.2rem;
}

.registrationaaawde {
  width: 3rem;
  height: 1.95rem;
  background: #f6f6f7;
  box-shadow: 0rem 0rem 0.06rem 0.01rem rgba(0, 32, 92, 0.5);
  border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
  opacity: 0.9;
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  /* margin-left: 10rem; */
}
.prependBox {
  background: #ffffff;
  background-color: #ffffff;
  border-radius: 0.08rem 0 0 0.08rem;
}
.regtionawdebox {
  background-color: #ffffff;
  opacity: 1;
  margin: 0 0.23rem;
  border-radius: 0.08rem 0.08rem 0 0;
  width: 2.52rem;
  height: 0.37rem;
}
.el-input__inner {
  border: none !important;
}
.prepend-icon {
  font-size: 0.16rem;
}
.submitFormaaaaa {
  width: 2.52rem;
  height: 0.4rem;
  margin-top: 0.18rem;
  background: #5189e9;
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
  font-size: 0.16rem;
  text-align: center;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4rem;
  cursor: pointer;
}
.registrationthyju {
  margin-left: 0.23rem;
}

.el-form-item__content {
  display: flex;
  color: #000000;
}
.el-input-group__append,
.el-input-group__prepend {
  background-color: #ffffff !important;
  border: none !important;
  padding: 0 0.1rem !important;
}

/* 移动端 */
@media only screen and (max-width: 768px) {
  .downommon {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .registrs {
    width: 2.29rem;
    height: 0.81rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/registrationLogindialog.png");
    /* z-index: 99; */
    position: inherit;
    margin: -0.51rem;
  }
  .registrats {
    width: 3rem;
    height: 1.95rem;
    background: #f6f6f7;
    box-shadow: 0rem 0rem 0.06rem 0.01rem rgba(0, 32, 92, 0.5);
    border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
    opacity: 0.9;
    position: inherit;
  }
  .regtionawdebox {
    background-color: #ffffff;
    opacity: 1;
    margin: 0 0.23rem;
    border-radius: 0.08rem 0.08rem 0 0;
    width: 2.52rem;
    height: 0.37rem;
  }
  .index-main .left-main {
    height: auto;
    width: auto;
  }
  .button-common .pc-download {
    display: none;
  }
  .right-main .title-words {
    width: auto;
  }

  .index-main .left-main .video-container {
    padding: 0.2rem 0.2rem;
  }

  .video-container i {
    position: absolute;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    left: 50%;
    top: 50%;
    margin-top: -0.25rem;
    margin-left: -0.25rem;
    background-size: 100% 100%;
  }

  .main-container .index-main .right-main {
    padding-left: 0;
    padding-top: 0.3rem;
  }

  .main-container .index-main .right-main .top-logo {
    display: none;
  }

  .index-main .right-main .title-words {
    text-align: center;
    font-size: 0.34rem;
    padding: 0 0.5rem;
    line-height: 0.47rem;
  }

  .right-main .button-common {
    padding-top: 0;
  }

  .right-main .button-common a {
    display: block;
    text-align: center;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 550px) and (max-width: 768px) {
  .registrationsacdd {
    font-size: 0.14rem;   padding-top: 0.28rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {

  .main-container .index-main .right-main {
    padding-top: 0.6rem;
  }

  .index-main > div {
    display: block;
  }

  .right-main {
    text-align: center;
  }

  .right-main .title-words {
    text-align: center;
    width: auto;
  }

  .index-main .left-main {
    width: auto;
  }

  .button-common .pc-download {
    display: none;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1280px) {

  .right-main .title-words {
    text-align: center;
    width: 80%;
  }

  .index-main .left-main {
    text-align: center;
    width: 80%;
  }
}
</style>