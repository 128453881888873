<template>
  <div>
    <div class="immediatelymodified">
      <div class="immediateText">立即匹配视力类型</div>
    </div>
    <div class="bacilliformisStyle">
      <!-- 弱视训练表单 -->
      <div class="bacilliformisStyleFrom">
        <div class="baisStyleFrom">
          <div class="baisStyleFromTitle">视力信息:</div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="bacilliForm"
            label-width="1rem"
            :label-position="'top'"
            style="margin-top: 0.35rem"
          >
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="弱视类型选择(单选)："
              prop="amblyopiaType"
            >
              <el-radio-group
                class="el-radio-group"
                v-model="ruleForm.amblyopiaType"
              >
                <el-radio :label="'MYOPIA'">近视型弱视</el-radio>
                <el-radio :label="'HYOEROPIA'">远视型弱视</el-radio>
                <el-radio :label="'ASTUGNATISM'">散光型弱视</el-radio>
                <el-radio :label="'OTHER'">其他型弱视</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="左右眼矫正视力是否接近或度数相差较大(单选):"
              prop="eyeDiffType"
            >
              <el-radio-group
                class="el-radio-group"
                v-model="ruleForm.eyeDiffType"
              >
                <el-radio class="long-radio" :label="'NEAR_LT_2'"
                  >接近: 左右眼矫正视力相差2行视标以内</el-radio
                >
                <el-radio class="long-radio" :label="'DIFF_GT_2'"
                  >不一样: 左右眼矫正视力相差2行视标及以上</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="双眼是否斜视(单选):"
              class="el-form--label-top el-form-item__label"
              prop="strabismusType"
            >
              <el-radio-group v-model="ruleForm.strabismusType">
                <el-radio :label="'LEFT_RIGHT_NONE'">左右眼都没有</el-radio>
                <el-radio :label="'LEFT_RIGHT_BOTH'">左右眼都斜视</el-radio>
                <el-radio :label="'ONLY_RIGHT'">仅右眼斜视</el-radio>
                <el-radio :label="'ONLY_LEFT'">仅左眼斜视</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="其他视力问题(可多选):"
              prop="otherProblem"
            >
              <!-- <el-radio-group
                class="problemsEncephalitis"
                v-model="ruleForm.otherProblem"
              >
                <el-radio :label="'CATARACT'">白内障</el-radio>
                <el-radio :label="'COLOR_WEAKNESS'">色弱</el-radio>
                <el-radio :label="'PARACENTRIC_GAZE'">旁中心注视</el-radio>
                <el-radio :label="'NYSTAGNUS'">眼颤</el-radio>
                <el-radio :label="'COLO_BLINDNESS'">色盲</el-radio>
                <el-radio :label="'FUNDUS_LESIONS'">眼底病变</el-radio>
                <el-radio :label="''">无</el-radio>
              </el-radio-group> -->
              <el-checkbox-group v-model="ruleForm.otherProblem">
                <el-checkbox :label="'CATARACT'">白内障</el-checkbox>
                <el-checkbox :label="'COLOR_WEAKNESS'">色弱</el-checkbox>
                <el-checkbox :label="'PARACENTRIC_GAZE'"
                  >旁中心注视</el-checkbox
                >
                <el-checkbox :label="'NYSTAGNUS'">眼颤</el-checkbox>
                <el-checkbox :label="'COLO_BLINDNESS'">色盲</el-checkbox>
                <el-checkbox :label="'FUNDUS_LESIONS'">眼底病变</el-checkbox>
                <!-- <el-checkbox :label="''">无</el-checkbox>-->
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="optBasicGradAccessFrom">
        <div class="baisStyleFrom">
          <div class="baisStyleFromTitle">基础信息:</div>
          <el-form
            :model="optBasicGradAccessFrom"
            :rules="rules"
            ref="baisStyleFrom"
            label-width="1rem"
            :label-position="'top'"
            style="margin-top: 0.25rem"
          >
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="姓名"
              prop="name"
            >
              <el-input
                style="width: 2.9rem;"
                v-model="optBasicGradAccessFrom.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="性别"
              prop="sex"
            >
              <el-select
                  style="width: 2.9rem"
                  v-model="optBasicGradAccessFrom.sex"
                  placeholder="请选择性别"
              >
                <el-option label="男" value="1" />
                <el-option label="女" value="2" />
              </el-select>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="出生日期"
              prop="birthDay"
            >
              <el-date-picker
                style="width: 2.9rem;"
                v-model="optBasicGradAccessFrom.birthDay"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="jsrq"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              class="el-form--label-top el-form-item__label"
              label="年龄"
              prop="type"
            >
              <el-input
                style="width: 2.9rem;"
                v-model="optBasicGradAccessFrom.age"
                placeholder="系统自动换算"
                readonly
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="submitForm" @click="submitForm">提交</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bacilliformis",
  data() {
    return {
      ruleForm: {
        amblyopiaType: "",
        eyeDiffType: "",
        strabismusType: "",
        otherProblem: [],
      },
      optBasicGradAccessFrom: {
        name: "",
        sex: "",
        birthDay: "",
        age: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "change" }],
        sex: [{ required: true, message: "请输入性别", trigger: "change" }],
        birthDay: [
          {
            type: "string",
            required: true,
            message: "请选择出生日期",
            trigger: "change",
          },
        ],
        amblyopiaType: [
          { required: true, message: "请选择弱视类型", trigger: "change" },
        ],
        eyeDiffType: [
          {
            required: true,
            message: "请选择左右眼差别类型",
            trigger: "change",
          },
        ],
        strabismusType: [
          { required: true, message: "请选择斜视类型", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    jsrq() {
      let birthdays = new Date(
        this.optBasicGradAccessFrom.birthDay.replace(/-/g, "/")
      );
      // console.log("生日转换时间", birthdays);
      let d = new Date();
      let age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      this.optBasicGradAccessFrom.age = age;
    },
    submitForm() {
      this.$refs["bacilliForm"].validate((valid) => {
        if (valid) {
          const token = localStorage.getItem("auth");
          if (!token || token === undefined || token === "" || token === null) {
            this.$router.push({
              path: "/",
              name: "login",
            });
            this.$message.warning("请先登录！！！");
            return;
          }
          const otherProblem = this.ruleForm.otherProblem.toString();
          let params = {
            // auth: token,
            sourceChannel: "guanwang",
            sourcePlatform: "web",
            dataSource: "guanwang",
            clientPlatform: "web", // 固定
            name: this.optBasicGradAccessFrom.name,
            sex: this.optBasicGradAccessFrom.sex,
            birthDay: this.optBasicGradAccessFrom.birthDay,
            amblyopiaType: this.ruleForm.amblyopiaType,
            eyeDiffType: this.ruleForm.eyeDiffType,
            strabismusType: this.ruleForm.strabismusType,
            otherProblem: otherProblem,
          };
          this.$axios({
            url: "app/v1.0/userSetmeal/match", //请求地址
            headers: {
              // 设置请求头
              auth: token,
            },
            method: "post", //请求方法
            data: params,
          }).then((res) => {
            if (res.data.code == 1) {
              this.$message.success("提交成功");
            } else {
              this.$message.warning(res.data.message);
            }
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
.immediatelymodified {
  width: 2.29rem;
  height: 0.81rem;
  position: absolute;
  top: 0.92rem;
  right: 1.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/title_bg.png");
  z-index: 99;
}
.immediateText {
  font-size: 0.2rem;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  padding-top: 0.2rem;
}
.bacilliformisStyle {
  width: 3.84rem;
  height: 13.48rem;
  background: #f6f6f7;
  box-shadow: 0rem 0rem 0.06rem 0.01rem rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
  opacity: 1;
  position: absolute;
  top: 1.32rem;
  right: 0.9rem;
}
.bacilliformisStyleFrom {
  margin-top: 0.64rem;
  margin-left: 0.24rem;
  width: 3.36rem;
  /*height: 6.94rem;*/
  background: #ffffff;
  box-shadow: 0rem 0rem 0.03rem 0.01rem rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
}
.baisStyleFrom {
  margin-left: 0.23rem;
}
.baisStyleFromTitle {
  font-size: 0.18rem;
  position: relative;
  top: 0.28rem;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #00205c;
}

.optBasicGradAccessFrom {
  width: 3.36rem;
  /*height: 4.41rem;*/
  margin-left: 0.24rem;
  margin-top: 0.27rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 0.03rem 0.01rem rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
}
.submitForm {
  width: 3.36rem;
  height: 0.4rem;
  margin-left: 0.24rem;
  margin-top: 0.24rem;
  background: #5189e9;
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  opacity: 1;
  font-size: 0.16rem;
  text-align: center;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4rem;
  cursor: pointer;
}
.el-form-item__content {
  display: flex;
  color: #000000; 
}
.el-form-item__content .el-radio {
  width: 50%;
  margin-right: 0;
  padding-bottom: 0.2rem;
  font-weight:100 !important;
  color: #565656;
}
.el-form-item__content .long-radio {
  width: 100%;
}

.el-form--label-top .el-form-item__label {
  vertical-align: none !important;
  display: block !important;
  margin-bottom: 0.1rem !important;
  padding: 0rem 0 0 0 !important;
}
.el-radio-button__inner,
.el-radio-group {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  align-content: space-between !important;
  /* height: 0.5rem; */
}
</style>