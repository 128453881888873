import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router.js'
import store from './vuex/store.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
// axios.defaults.headers['Authorization'] = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
// axios.defaults.headers['sourceChannel'] = 'guanwang'
// axios.defaults.headers['sourcePlatform'] = 'web'
// axios.defaults.headers['dataSource'] = 'guanwang'
axios.defaults.headers['Content-Type'] = 'application/json'
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/app' //关键代码
Vue.config.productionTip = false

// 这个方式可以在任何组件里直接用 this.$EventBus 调用
// Vue.prototype.$EventBus = EventBus
Vue.use(Vuex)
Vue.use(ElementUI);

new Vue({
  router, //key名字必须命名为router
  store,
  render: h => h(App)
}).$mount('#app')