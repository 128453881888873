<template>
  <div id="top-nav">
    <div class="header-container">
      <i class="logo-icon"></i>
      <!-- PC端 -->
      <div class="navs-ul">
        <span
          v-for="(item, index) in navList"
          :key="index"
          :class="activeIndex === index ? 'navs-li active' : 'navs-li'"
          @click="changeNav(index)"
          >{{ item.name }}</span
        >
      </div>
      <div class="right-nav">
        <div class="icon-container">
          <div class="icon-block">
            <i class="common-icon qq-icon"></i>
            <img
              class="common-img"
              src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-missBruceLee-QQ.jpg"
            />
          </div>
          <div class="icon-block">
            <i class="common-icon wechat-icon"></i>
            <img
              class="common-img"
              src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-officialAccount.jpg"
            />
          </div>
          <div class="icon-block">
            <i class="common-icon sina-icon"></i>
            <img
              class="common-img"
              src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-microblog.jpg"
            />
          </div>
          <div class="icon-block">
            <i class="common-icon custome-icon"></i>
            <img
              class="common-img"
              src="https://oss-kds-eyes.com/ruoshi/common/guanwnag/qr-customerService-Wechat.jpg"
            />
          </div>
        </div>
        <button
          v-if="!nickName"
          class="common-button login-button"
          @click="loginPage"
        >
          登录
        </button>
        <button
          v-if="!nickName"
          class="common-button regist-button"
          @click="registPage"
        >
          注册
        </button>
        <span class="common-button nick-name" v-if="nickName">{{
          nickName
        }}</span>
        <i v-if="nickName" class="logout-icon" @click="logoutPage"></i>
      </div>
      <!-- 移动端 -->
      <div class="right-categarys">
        <i
          :class="showCategary ? 'categary-active' : 'categary-default'"
          @click="showCategary = !showCategary"
        ></i>
      </div>
      <div class="navs-pc-ul" v-if="showCategary">
        <div class="top">
          <i
            :class="showCategary ? 'categary-active' : 'categary-default'"
            @click="showCategary = !showCategary"
          ></i>
        </div>
        <div
          v-for="(item, index) in navList"
          :key="index"
          :class="
            activeIndex === index ? 'navs-mobile-li active' : 'navs-mobile-li'
          "
          @click="changeNav(index)"
        >
          <i></i>
          {{ item.name }}
        </div>
        <div class="navs-mobile-li logout-li">
          <div class="common-line">{{ nickName }}</div>
          <div class="common-line" @click="logoutPage">
            <i></i>
            <span>退出</span>
          </div>
        </div>
      </div>
      <!-- <div class="middle-container">
        <span v-if="!nickName" @click="registPage">注册</span>
        <span v-if="!nickName">|</span>
        <span v-if="!nickName" @click="loginPage">登录</span>
      </div> -->
    </div>
    <!-- 弱视训练 -->
    <div class="training-header-title" v-if="activeIndex == 1">
      <div class="subaraining-title">
        Online amblyopia training anytime, anywhere
      </div>
      <div class="main-title">在线弱视训练 随时随地练习</div>
    </div>
    <!-- 产品介绍 -->
    <div class="header-title" v-if="activeIndex == 2">
      <div class="sub-title">Product Presention</div>
      <div class="main-title">产品介绍</div>
    </div>
    <!-- 知识问答 -->
    <div class="knowledgeQA-title" v-if="activeIndex == 3">
      <div class="main-title">深度了解斜弱视</div>
      <div class="input-container">
        <div class="input-main">
          <input
            type="text"
            v-model="inputValue"
            placeholder="输入您想知道的问题"
            @change="changeSearch()"
          />
          <i class="icon-common search-icon"></i>
          <i class="icon-common down-icon"></i>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="header-title" v-if="activeIndex == 4">
      <div class="sub-title">About Us</div>
      <div class="main-title">关于我们</div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../utils/Eventbus'
export default {
  name: 'topNav',
  props: {
    msg: String,
  },
  data() {
    return {
      activeIndex: 0, //默认选中
      navList: [
        {
          name: '首页',
        },
        {
          name: '产品介绍',
        },
        {
          name: '弱视训练',
        },
        {
          name: '知识问答',
        },
        {
          name: '关于我们',
        },
      ],
      showCategary: false, //目录展开和收起
      inputValue: '',
      nickName: localStorage.getItem('ssjenickName'),
    }
  },
  computed: {
    // ...mapGetters(["getnickName"]),
    // nickName() {
    //   return this.$store.state.nickName;
    // },
  },
  watch: {
    //接收父组件传入的表单数据
    nickName: {
      immediate: true,
      deep: true,
      handler(val) {
        // console.log(val);
        this.nickName = val
      },
    },
  },
  created() {},
  mounted() {
    EventBus.$on('jumpages', val => {
      this.activeIndex = val
    })
  },
  beforeDestroy() {
    EventBus.$off('jumpages')
  },
  methods: {
    changeNav(index) {
      this.activeIndex = index
      this.$emit('changeNav', index)
    },
    changeSearch() {
      console.log(this.inputValue);
      this.$store.commit('setinputValue', this.inputValue)
    },
    /**
     * 登录
     */
    loginPage() {
      this.$router.push({
        path: '/',
        name: 'login',
      })
    },
    /**
     * 退出登录
     */
    logoutPage() {
      this.nickName = ''
      // this.$store.commit("setnickName", "");
      localStorage.setItem('ssjenickName', '')
      localStorage.setItem('auth', '')
    },

    /**
     * 注册
     */
    registPage() {
      this.$router.push({
        path: '/regist',
        name: 'regist',
      })
    },
  },
}
</script>

<style>
.header-container {
  font-size: 0;
}
.header-container .logo-icon {
  display: inline-block;
  /*width: 1.25rem;*/
  /*height: 0.4rem;*/
  /*margin-top: 0.23rem;*/
  width: 0.94rem;
  height: 0.3rem;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  background-size: 100% 100%;
  background-image: url('../assets/index-logo.png');
}
.header-container .navs-ul {
  display: inline-block;
  /*margin-left: 1.3rem;*/
  margin-left: 1.02rem;
  vertical-align: top;
}
.navs-ul .navs-li {
  display: inline-block;
  /*padding: 0.3rem 0.2rem;*/
  /*font-size: 0.14rem;*/
  padding: 0.28rem 0.2rem;
  font-size: 0.11rem;
  color: #fff;
  font-weight: bold;
  font-family: 'Source Han Sans CN-Bold, Source Han Sans CN';
}
.navs-ul .active {
  border-top: 0.04rem solid #19cdb8;
}
.header-container .right-nav {
  display: inline-block;
  float: right;
  padding-top: 0.28rem;
}
.right-nav .icon-block {
  display: inline-block;
  /*width: 0.2rem;*/
  /*height: 0.2rem;*/
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.16rem;
  position: relative;
}
.right-nav .common-icon {
  display: inline-block;
  /*width: 0.2rem;*/
  /*height: 0.2rem;*/
  width: 0.15rem;
  height: 0.15rem;
  background-size: 100% 100%;
}
.icon-block .common-img {
  display: none;
  position: absolute;
  top: 0.5rem;
  left: -0.64rem;
  width: 1.92rem;
  /* height: 128px; */
  border-radius: 0.1rem;
  z-index: 10;
}
.icon-block:hover .common-img {
  z-index: 999999;
  display: inline-block !important;
}
.right-nav .common-button {
  display: inline-block;
  /*width: 0.8rem;*/
  /*height: 0.32rem;*/
  /*line-height: 0.32rem;*/
  width: 0.6rem;
  height: 0.24rem;
  line-height: 0.24rem;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 0.11rem;
  border: none;
  vertical-align: top;
}
.right-nav .nick-name {
  margin: 0 10px;
}
.right-nav .icon-container {
  display: inline-block;
  padding-top: 0.06rem;
  vertical-align: top;
}
.right-nav .login-button {
  background: #19cdb8;
  margin-left: 0.1rem;
  margin-right: 0.08rem;
}
.right-nav .regist-button {
  background: #5189e9;
}
.right-nav .logout-icon {
  display: inline-block;
  /*width: 0.32rem;*/
  /*height: 0.32rem;*/
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
  background-size: 100% 100%;
  background-image: url('../assets/logout-icon.png');
}
.right-nav .qq-icon {
  background-image: url('../assets/qq-icon.png');
}
.right-nav .wechat-icon {
  background-image: url('../assets/wechat-icon.png');
}
.right-nav .sina-icon {
  background-image: url('../assets/sina-icon.png');
}
.right-nav .custome-icon {
  background-image: url('../assets/custome-icon.png');
}
/* 产品介绍 */
.header-title {
  padding-top: 1.17rem;
}
.header-title .sub-title {
  /*font-size: 0.16rem;*/
  font-size: 0.12rem;
  text-align: center;
  color: #fff;
  font-family: 'Source Han Sans CN-Normal, Source Han Sans CN';
}
.header-title .main-title {
  /*font-size: 0.48rem;*/
  font-size: 0.36rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-family: 'Source Han Sans CN-Normal, Source Han Sans CN';
}

/**弱视训练 */
.training-header-title {
  padding-top: 0.17rem;
  color: #fff;
  margin-left: 1.97rem;
  margin-top: 0.7rem;
}

.training-header-title .subaraining-title {
  font-size: 0.13rem;
  color: #19cdb8;
  opacity: 66%;
  font-family: 'Source Han Sans CN-Normal';
}

.training-header-title .main-title {
  font-weight: 600;
  font-size: 0.56rem;
  color: #ffffff;
  font-family: 'Source Han Sans CN-Bold, Source Han Sans CN';
}

/* 知识问答 */
.knowledgeQA-title {
  padding-top: 0.5rem;
}
.knowledgeQA-title .main-title {
  /*font-size: 0.48rem;*/
  font-size: 0.36rem;
  text-align: center;
  font-family: 'Source Han Sans CN-Bold, Source Han Sans CN';
  font-weight: bold;
  color: #ffffff;
}
.input-container {
  /*height: 0.56rem;*/
  height: 0.42rem;
  padding: 0 2.2rem;
  margin-top: 0.1rem;
}
.input-main {
  /*height: 0.56rem;*/
  height: 0.42rem;
  background: #ffffff;
  /*border-radius: 0.5rem;*/
  border-radius: 0.38rem;
  position: relative;
  font-size: 0;
}
.input-main input {
  display: inline-block;
  width: 100%;
  /*height: 0.56rem;*/
  /*line-height: 0.56rem;*/
  height: 0.42rem;
  line-height: 0.42rem;
  color: #565656;
  /*font-size: 0.14rem;*/
  font-size: 0.11rem;
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 0 0.56rem;
  border-radius: 0.5rem;
}
.input-main .icon-common {
  position: absolute;
  display: inline-block;
  /*width: 0.24rem;*/
  /*height: 0.24rem;*/
  width: 0.18rem;
  height: 0.18rem;
  /*top: 0.16rem;*/
  top: 0.12rem;
  background-size: 100% 100%;
}
.input-main .search-icon {
  left: 0.16rem;
  background-image: url('../assets/search-icon.png');
}
.input-main .down-icon {
  right: 0.16rem;
  background-image: url('../assets/down-icon.png');
}
@media only screen and (min-width: 768px) {
  #top-nav .header-container {
    position: relative;
    padding: 0 0.9rem;
  }
  #top-nav .header-container .navs-ul {
    display: inline-block;
  }
  #top-nav .header-container .right-nav {
    display: inline-block;
    vertical-align: top;
  }
}
/* 移动端 */
@media only screen and (max-width: 768px) {
  #top-nav .header-container {
    padding: 0;
  }
  #top-nav .header-container .navs-ul {
    display: none;
  }
  #top-nav .header-container .right-nav {
    display: none;
  }
  #top-nav .header-container .right-categarys {
    display: inline-block;
    position: relative;
    width: 0.34rem;
    height: 0.34rem;
    float: right;
    margin-top: 0.23rem;
  }
  #top-nav .header-container .top {
    display: block;
    width: 100%;
    text-align: right;
  }
  .knowledgeQA-title .main-title {
    font-size: 0.44rem;
  }
  .right-categarys > i,
  .top > i {
    display: inline-block;
    /*width: 0.34rem;*/
    /*height: 0.34rem;*/
    width: 0.26rem;
    height: 0.26rem;
    background-size: 100% 100%;
  }

  .categary-active {
    background-image: url('../assets/categary-close.png');
    margin-right: 0.15rem;
  }
  .categary-default {
    background-image: url('../assets/categary-icon.png');
  }
  .navs-pc-ul {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0.2rem 0;
    background-color: #19cdb8;
    color: #fff;
    box-sizing: border-box;
    z-index: 999;
  }
  .navs-mobile-li {
    /*height: 0.6rem;*/
    /*line-height: 0.6rem;*/
    height: 0.4rem;
    line-height: 0.54rem;
    color: #fff;
    font-size: 0.13rem;
    text-align: right;
    padding: 0 0.2rem;
  }
  .navs-pc-ul .active i {
    display: inline-block;
    /*width: 0.12rem;*/
    /*height: 0.12rem;*/
    width: 0.09rem;
    height: 0.09rem;
    margin-right: 10px;
    background-size: 100% 100%;
    background-image: url('../assets/circle-icon.png');
  }
  .logout-li {
    margin-top: 0.4rem;
  }
  .logout-li .common-line {
    display: inline-block;
    height: 0.6rem;
    line-height: 0.6rem;
    vertical-align: top;
    width: 50%;
  }
  .logout-li .common-line:first-child {
    text-align: left;
  }
  .logout-li i {
    display: inline-block;
    /*width: 0.32rem;*/
    /*height: 0.32rem;*/
    width: 0.24rem;
    height: 0.24rem;
    margin-top: 0.14rem;
    margin-right: 0.1rem;
    background-size: 100% 100%;
    background-image: url('../assets/logout-icon.png');
  }
  .logout-li span {
    display: inline-block;
    height: 0.6rem;
    line-height: 0.54rem;
    vertical-align: top;
  }
  .middle-container {
    padding: 0.2rem 0;
    text-align: right;
  }
  .middle-container span {
    display: inline-block;
    padding: 0 0.1rem;
    font-size: 0.16rem;
    color: #fff;
  }
  .header-title {
    padding-top: 0.8rem;
  }
  .input-container {
    padding: 0 0;
  }
  .knowledgeQA-title {
    padding-top: 0.2rem;
  }
}
</style>
