<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<script>
fnResize();
window.onresize = function () {
  fnResize();
};
function fnResize() {
  var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
  if (deviceWidth > 768) {
    document.documentElement.style.fontSize = deviceWidth / 14.4 + "px";
  }else{
    document.documentElement.style.fontSize = deviceWidth / 3.75 + "px";

  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>