
<template>
  <div>
    <div class="navs-containers"></div>
    <div class="backUpPage" @click="goBack"></div>
    <div class="logoBox"></div>
    <div class="zhishiBox">
      <div class="boxTitle" v-html="knowledgeList.title"/>
      <div class="boxtontent" v-html="knowledgeList.content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bacilliformisDetails',
  data() {
    return {
      // 文章id从地址上获取
      textIndex: this.$route.query.id,
      knowledgeList: {},
    }
  },
  mounted() {
    this.getanswertemp()
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getanswertemp() {
      const obj = {
        sourceChannel: 'guanwang',
        sourcePlatform: 'web',
        clientPlatform: 'web',
        dataSource: 'guanwang',
      }
      this.$axios({
        url: 'app/v1.0/guanwang/listQuestionAnswer', //请求地址
        method: 'post', //请求方法
        data: obj,
      }).then(res => {
        this.knowledgeList = res.data.data.questionAnswerList[this.textIndex]
        // console.log(this.knowledgeList, 'knowledgeList')
      })
    },
  },
}
</script>

<style>
.navs-containers {
  height: 1.5rem !important;
  background-size: 190% 100%;
  background-image: url('../assets/knowledgeQA-bg.png');
  /* background-position: center; */
}
.backUpPage {
  cursor: pointer;
  position: absolute;
  left: 0.2rem;
  top: 0.35rem;
  width: 0.28rem;
  height: 0.24rem;
  background-image: url('../assets/icon_back.png');
  background-size: 100% 100%;
}
.logoBox {
  position: absolute;
  background-image: url('../assets/icon_login_logo_r.png');
  background-size: 100% 100%;
  width: 1.25rem;
  height: 0.4rem;
  top: 0.3rem;
  right: 0.2rem;
}
.zhishiBox {
  width: 3.75rem;
  height: 6.94rem;
  background: #ffffff;
  border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
  opacity: 1;
  position: absolute;
  top: 1.3rem;
}
.boxTitle {
  font-size: 0.14rem;
  text-align: center;

  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #00205c;
  line-height: 0.59rem;
}
.boxtontent {
  padding: 0.2rem;
  font-size: 0.15rem;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #565656;
  line-height: 0.22rem;
}
</style>
