<template>
  <div id="knowledgeQA-container">
    <div class="knowledgeQA-ul">
      <div
        class="knowledgeQA-li"
        v-for="(item, index) in knowledgeQAList"
        :key="index"
        @click="screenWidth > 768 ? openDialog(item) : openNewBox(index)"
      >
        <div class="knowledgeQA-li-img">
          <img class="knowledgeQA-li-img" :src="item.pictureUrl" alt="" />
        </div>
        <div class="knowledgeQA-contents">
          <div class="knowledgeQA-li-title" v-html="item.title"/>
          <div class="knowledgeQA-li-content" v-html="item.content"/>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialogData.title"
      width="50%"
      center
      custom-class="dialogBox"
      :visible.sync="outerVisible"
    >
      <div class="dialogBoxContents" v-html="dialogData.content"/>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "knowledgeQA",
  props: {},
  data() {
    return {
      knowledgeQAList: [],
      outerVisible: false,
      dialogData: {},
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  },
  computed: {
    ...mapGetters(["getinputValue"]),
    inputValue() {
      return this.$store.state.inputValue;
      // console.log(this.$store.state.inputValue);
    },
  },
  watch: {
    //接收父组件传入的表单数据
    inputValue: {
      immediate: true,
      deep: true,
      handler(val) {
        this.searchChange(val);
      },
    },
  },
  mounted() {
    this.getanswertemp();
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    });
  },
  methods: {
    getanswertemp() {
      const obj = {
        sourceChannel: "guanwang",
        sourcePlatform: "web",
        clientPlatform: "web",
        dataSource: "guanwang",
      };
      this.$axios({
        url: "app/v1.0/guanwang/listQuestionAnswer", //请求地址
        method: "post", //请求方法
        data: obj,
      }).then((res) => {
        this.knowledgeQAList = res.data.data.questionAnswerList;
      });
    },
    searchChange(val) {
      if (val === ""){
        this.getanswertemp();
      }
      let knowledgeQAList = [];
      if (!this.knowledgeQAList || !this.knowledgeQAList.length) {
        return;
      }
      knowledgeQAList = this.knowledgeQAList.filter((item) => {
        return item.title.includes(val);
      });
      this.knowledgeQAList = knowledgeQAList;
    },
    openDialog(data) {
      this.outerVisible = true;
      this.dialogData = data;
    },
    // 跳转到新的页面
    openNewBox(data) {
      this.$router.push({
        path: "/knowledge",
        query: {
          id: data,
        },
      });
    },
  },
};
</script>

<style>
#knowledgeQA-container {
  padding: 0 2.2rem;
}
.knowledgeQA-li {
  cursor: pointer;
  margin: 0.5rem 0;
  display: flex;
}
.knowledgeQA-li-img {
  width: 2rem;
  height: 1.8rem;
}
.knowledgeQA-contents {
  /* height: 2rem; */
  margin-left: 0.32rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.knowledgeQA-li-title {
  /*font-size: 0.22rem;*/
  font-size: 0.2rem;
  padding-right: 0.4rem;
  box-sizing: border-box;
  font-family: "Source Han Sans CN-Medium, Source Han Sans CN";
  font-weight: 500;
  color: #00205c;
  margin-bottom: 0.2rem;
}
.knowledgeQA-li .hide {
  height: 0.6rem;
  overflow: hidden;
}
.knowledgeQA-li .hide:not(:nth-child(n + 2)) {
  display: none;
}
.knowledgeQA-li-content {
  /*font-size: 0.14rem;*/
  font-size: 0.11rem;
  font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
  font-weight: 400;
  color: #565656;
  line-height: 0.28rem;
  overflow: hidden;
}
.knowledgeQADialog {
  position: absolute;
  top: 50%;
  left: 50%;
}

.dialogBox .el-dialog__header {
  margin-right: 0.16rem !important;
  word-break: break-all !important;
  height: 0.5rem !important;
}
.dialogBox .el-dialog__header .el-dialog__title {
  font-size: 0.24rem !important;
  font-family: "Source Han Sans CN-Medium, Source Han Sans CN" !important;
  font-weight: 500 !important;
  position: absolute !important;
  color: #00205c !important;
  left: 14% !important;
  top: 50% !important;
}
.dialogBox .el-dialog__body {
  height: 2.5rem !important;
  background: #ffffff !important;
  position: absolute !important;
  overflow-y: auto !important;
  text-align: inherit !important;
}
.dialogBoxContents {
  font-size: 0.14rem;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #565656;
  line-height: 0.28rem;
}
/* 移动端 */
@media only screen and (max-width: 768px) {
  #knowledgeQA-container {
    padding: 0 0.2rem;
  }
  .knowledgeQA-li-img {
    width: 1.1rem;
    height: 1rem;
  }
  .knowledgeQA-li-title {
    font-size: 0.12rem;
    padding-right: 0.1rem;
    box-sizing: border-box;
    font-family: "Source Han Sans CN-Medium, Source Han Sans CN";
    font-weight: 500;
    color: #00205c;
    margin-bottom: 0.05rem;
  }
  .knowledgeQA-contents {
    margin-left: 0.32rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .knowledgeQA-li-content {
    font-size: 0.11rem;
    font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
    font-weight: 400;
    color: #565656;
    line-height: 0.2rem;
    width: 1.77rem;
    height: 0.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* 
  隐藏内容
  */
  .el-dialog__wrapper {
    display: none !important;
  }
  .dialogBox {
    display: none;
  }
}
</style>
