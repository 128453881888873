<template>
  <div id="product-container">
    <div class="product-main1">
      <el-row :gutter="10">
<!--        <el-col class="left-images" :xs="24" :sm="24" :md="16" :lg="16" :xl="16">-->
        <el-col class="left-images" :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <div class="left-main"></div>
        </el-col>
<!--        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">-->
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <div class="right-main">
            <div class="top-words top-words-1"
            >圣视尔佳弱视训练HD，是一款专为儿童弱视训练研发，产品基于CAM弱视训练理念，通过多媒体的弱视训练系统视觉交互技术，结合脑视觉、眼科及儿童心理等设计理论，在该领域拥有自主知识产权及软件著作权等。</div>
            <div class="top-words top-words-2">
              产品结构：
              <span class="green-words">虚拟视觉刺激模块、双眼视功能模块、红蓝眼镜组等。</span>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="product-main1-bg"></div>
    </div>
    <!-- 第二部分 -->
    <div class="product-main2">
      <div class="main2-container">
        <el-row :gutter="10">
          <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
            <div class="left-block">
              <div class="title-main left-color">圣视尔佳APP</div>
              <div class="title-sub left-color">弱视训练系统</div>
              <div class="block-content">
                <div class="top-content">
                  <div class="top-title">
                    <i class="top-title-icon left-icon"></i>
                    <span class="top-title-words">弱视软件训练更注重于大脑训练</span>
                  </div>
                  <div class="top-title-desc">利用大脑神经系统可塑性，通过视觉刺激及学习，激活视觉信号通路，以达到辅助弱视训练的目的。</div>
                </div>
                <div class="main-ul">
                  <div class="main-li">
                    <div class="main-li-title">不枯燥</div>
                    <div class="main-li-desc">结合儿童心理，将游戏融入训练，不枯燥</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">个性化定制</div>
                    <div class="main-li-desc">个性化定制训练内容、光栅背景及时长</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">定期改进，用户体验更完善</div>
                    <div class="main-li-desc">定期更新训练难度，游戏内容及满足用户需求</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">有专业辅导老师</div>
                    <div class="main-li-desc">专业辅导老师在线1对1指导，针对性匹配方案</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">训练动力满满</div>
                    <div class="main-li-desc">萌宠陪伴及激励机制保护孩子训练兴趣快速提升</div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10">
            <div class="right-block">
              <div class="title-main right-color">传统模式</div>
              <div class="title-sub right-color">弱视训练</div>
              <div class="block-content">
                <div class="top-content">
                  <div class="top-title">
                    <i class="top-title-icon right-icon"></i>
                    <span class="top-title-words">着重对眼睛视觉器官进行训练</span>
                  </div>
                  <div class="top-title-desc">通常采用遮盖、红光闪烁、穿针、穿珠等方法，对视力进行提高训练。</div>
                </div>
                <div class="main-ul">
                  <div class="main-li">
                    <div class="main-li-title">枯燥乏味</div>
                    <div class="main-li-desc">训练模式单一枯燥乏味</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">单一化训练</div>
                    <div class="main-li-desc">训练缺乏针对性</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">一尘不变</div>
                    <div class="main-li-desc">训练缺乏针对性</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">无专业辅导老师</div>
                    <div class="main-li-desc">孩子依从性低</div>
                  </div>
                  <div class="main-li">
                    <div class="main-li-title">无训练动力</div>
                    <div class="main-li-desc">没有兴趣，提升遇瓶颈</div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <i class="icon-common icon1"></i>
      <i class="icon-common icon2"></i>
      <i class="icon-common icon3"></i>
      <i class="icon-common icon4"></i>
      <i class="icon-common icon5"></i>
    </div>
    <!-- 第三部分 -->
    <div class="product-main3">
      <div class="main3-left">
        <div class="main3-subtitle">Product features</div>
        <div class="main3-title">产品优势</div>
        <div class="main3-ul">
          <div class="main3-li">
            <div class="main3-li-left">平台兼容 多端支持</div>
            <div class="main3-li-right">同时兼容Windows、iOS/iPadOS、Android等主流操作系统</div>
          </div>
          <div class="main3-li">
            <div class="main3-li-left">个性匹配 数据支持</div>
            <div class="main3-li-right">大数据支持，检查结果实时联动，自动匹配训练方案。</div>
          </div>
          <div class="main3-li">
            <div class="main3-li-left">丰富训练 科学分级</div>
            <div class="main3-li-right">丰富训练，科学支持用户不同年龄段的训练需求。</div>
          </div>
          <div class="main3-li">
            <div class="main3-li-left">萌宠陪练 高效有趣</div>
            <div class="main3-li-right">电子宠物日常监督，提高训练兴趣。每日可做多次训练。</div>
          </div>
        </div>
      </div>
      <div class="main3-right-container">
        <div class="main3-right"></div>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="product-main4">
      <div class="product-main4-ul">
        <div class="product-main4-li">
          <div class="main4-li-bg card1">
            <div class="main4-li-title">视觉刺激</div>
            <div class="main4-li-content">通过多种刺激模式，刺激视 觉通道增强视网觉通道增强视网膜觉通道增强视网膜觉通道增强视网膜</div>
          </div>
        </div>
        <div class="product-main4-li">
          <div class="main4-li-bg card2">
            <div class="main4-li-title">视觉精细</div>
            <div class="main4-li-content">弱视儿童进行精细目力训练时有意识地强迫觉通道增强视网膜觉通道增强视网膜觉通道增强视网膜</div>
          </div>
        </div>
        <div class="product-main4-li">
          <div class="main4-li-bg card3">
            <div class="main4-li-title">同时视</div>
            <div class="main4-li-content">利用红蓝眼镜将屏幕上红蓝视标过滤，觉通道增强视网膜觉通道增强视网膜觉通道增强视网膜</div>
          </div>
        </div>
        <div class="product-main4-li">
          <div class="main4-li-bg card4">
            <div class="main4-li-title">融合</div>
            <div class="main4-li-content">通过多种刺激模式，刺激视 觉通道增强视网膜觉通道增强视网膜觉通道增强视网膜</div>
          </div>
        </div>
        <div class="product-main4-li">
          <div class="main4-li-bg card5">
            <div class="main4-li-title">立体视</div>
            <div class="main4-li-content">利用红蓝眼镜将屏幕上红蓝视标过滤，觉通道增强视网膜觉通道增强视网膜觉通道增强视网膜</div>
          </div>
        </div>
        <div class="product-main4-li">
          <div class="main4-li-bg card6">
            <div class="main4-li-title">红光闪烁</div>
            <div class="main4-li-content">人眼视网膜成份包含视锥细胞和视杆细胞，视...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style>
#product-container .index-main {
  padding: 0;
  padding-top: 0.75rem;
}
#product-container {
  overflow-y: scroll;
  overflow-x: hidden;
}
.product-main1 {
  padding-top: 0.95rem;
  position: relative;
}
.product-main1-bg {
  position: absolute;
  left: 0.8rem;
  top: 0.4rem;
  z-index: -1;
  width: 10.57rem;
  height: 4.7rem;
  background-size: 100% 100%;
  background-image: url("../assets/product-bg2.png");
}
.product-main1 > div {
  /* display: inline-block; */
}
.left-images {
  padding-left: 1.5rem !important;
}
.product-main1 .left-main {
  width: 95%;
  /*height: 4.9rem;*/
  height: 5.45rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../assets/product-bg1.png");
}
.product-main1 .right-main {
  vertical-align: top;
  padding-left: 0.85rem;
  width: 3.1rem;
}
.product-main1 .top-words {
  /*font-size: 0.16rem;*/
  width: 2.38rem;
  height: 1.32rem;
  font-size: 0.12rem;
  font-family: "Source Han Sans CN-Medium, Source Han Sans CN";
  font-weight: 500;
  color: #00205c;
  line-height: 0.3rem;
}
.top-words-1 {
  margin-top: 0.8rem;
}
.top-words-2 {
  margin-top: 0.4rem;
}
.top-words .green-words {
  color: #00be99;
}
.product-main2 {
  position: relative;
  z-index: 2;
  height: 8.77rem;
  padding: 0.75rem 3.2rem 0.85rem 3.2rem;
  box-sizing: border-box;
  margin-top: -0.38rem;
  background-size: 100% 100%;
  background-image: url("../assets/product-main2.png");
}
.main2-container > div {
  display: inline-block;
}
.product-main2 .left-block {
  /* width: 444px;
  height: 716px; */
  padding: 0.46rem 0.32rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 0.2rem 1px rgba(6, 52, 53, 0.16);
  border-radius: 0.2rem;
}
.product-main2 .right-block {
  /* width: 390px;
  height: 669px; */
  padding: 0.24rem 0.32rem 0.24rem 0.62rem;
  background: #cdf7f8;
  border-radius: 0.2rem;
  float: right;
  margin-left: -0.4rem;
  vertical-align: top;
  margin-top: 0.24rem;
  box-sizing: border-box;
}
.main2-container .title-main {
  /*font-size: 0.26rem;*/
  font-size: 0.2rem;
  font-weight: bold;
  padding-left: 0.24rem;
  font-family: " Source Han Sans CN-Normal, Source Han Sans CN";
}
.left-color {
  color: #e41475;
}
.right-color {
  color: #018c90;
}
.main2-container .title-sub {
  /*font-size: 0.26rem;*/
  font-size: 0.2rem;
  padding-left: 0.24rem;
  padding-bottom: 0.17rem;
  font-family: " Source Han Sans CN-Normal, Source Han Sans CN";
}
.left-block .title-sub {
  border-bottom: 2px solid #e41475;
}
.right-block .title-sub {
  border-bottom: 2px solid #018c90;
}
.main2-container .block-content {
  padding: 0.34rem 0.24rem 0 0.24rem;
}
.left-block .block-content {
  color: #00205c;
  /*font-size: 0.13rem;*/
  font-size: 0.11rem;
}
.right-block .block-content {
  color: #018c90;
  /*font-size: 0.13rem;*/
  font-size: 0.11rem;
}
.top-content .top-title {
}
.top-title .top-title-icon {
  display: inline-block;
  width: 0.22rem;
  height: 0.22rem;
  background-size: 100% 100%;
}
.top-title .left-icon {
  background-image: url("../assets/product-icon1.png");
}
.top-title .right-icon {
  background-image: url("../assets/product-icon2.png");
}
.top-title .top-title-words {
  vertical-align: top;
  margin-left: 0.08rem;
  font-weight: bold;
}
.top-content .top-title-desc {
  /*font-size: 0.14rem;*/
  font-size: 0.11rem;
  line-height: 0.26rem;
  margin: 0.2rem 0;
}
.block-content .main-ul {
}
.main-ul .main-li {
  margin-top: 0.1rem;
}
.main-li .main-li-title {
  display: inline-block;
  height: 0.32rem;
  line-height: 0.32rem;
  padding: 0 0.16rem;
  text-align: center;
  background: #ff7dba;
  color: #fff;
  border-radius: 0.5rem;
}
.right-block .main-li .main-li-title {
  background: #ade6e8;
  color: #018c90;
}
.main-li .main-li-desc {
  line-height: 0.32rem;
  padding-left: 0.1rem;
}
.product-main2 .icon-common {
  position: absolute;
  display: inline-block;
  background-size: 100% 100%;
}
.product-main2 .icon1 {
  width: 1.25rem;
  height: 1.25rem;
  top: 0.7rem;
  left: 0.6rem;
  background-image: url("../assets/product-main2-icon1.png");
}
.product-main2 .icon2 {
  width: 1.88rem;
  height: 1.88rem;
  top: 1.7rem;
  right: 0.53rem;
  background-image: url("../assets/product-main2-icon2.png");
}
.product-main2 .icon3 {
  width: 3.98rem;
  height: 0.66rem;
  bottom: 1.2rem;
  right: -1.5rem;
  background-image: url("../assets/product-main2-icon3.png");
}
.product-main2 .icon4 {
  width: 1.23rem;
  height: 1.23rem;
  bottom: -0.7rem;
  left: 4.13rem;
  background-image: url("../assets/product-main2-icon4.png");
}
.product-main2 .icon5 {
  width: 1.23rem;
  height: 1.23rem;
  bottom: -1.23rem;
  left: 7.36rem;
  z-index: -1;
  background-image: url("../assets/product-main2-icon5.png");
}
/* 第三部分 */
.product-main3 {
  padding-left: 2.08rem;
  padding-top: 0.6rem;
}
.product-main3 > div {
  display: inline-block;
}
.product-main3 .main3-left {
  width: 5.27rem;
}
.main3-left .main3-subtitle {
  /*font-size: 0.16rem;*/
  font-size: 0.12rem;
  font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
  font-weight: 400;
  color: #00a887;
  line-height: 0.18rem;
}
.main3-left .main3-title {
  /*font-size: 0.40rem;*/
  font-size: 0.3rem;
  font-family: "Source Han Sans CN-Regular, Source Han Sans CN";
  font-weight: bold;
  color: #00205c;
  margin-top: 0.10rem;
  margin-bottom: 0.10rem;
  line-height: 0.18rem;
}
.main3-ul {
}
.main3-ul .main3-li {
  padding: 0.18rem 0;
  border-bottom: 1px solid #d6d8e3;
  font-size: 0;
}
.main3-li > div {
  display: inline-block;
}
.main3-li-left {
  /*width: 0.75rem;*/
  width: 0.56rem;
  height: 0.31rem;
  /*line-height: 0.40rem;*/
  /*font-size: 0.16rem;*/
  font-size: 0.11rem;
  font-family: "Source Han Sans CN-Medium, Source Han Sans CN";
  font-weight: 500;
  color: #00205c;
  line-height: 0.2rem;
}
.main3-li-right {
  /*line-height: 0.80rem;*/
  vertical-align: top;
  /*font-size: 0.14rem;*/
  font-size: 0.11rem;
  font-family: "Source Han Sans CN-Medium, Source Han Sans CN";
  font-weight: 400;
  color: #565656;
  margin-left: 0.32rem;
  line-height: 0.36rem;
}
.main3-right-container {
  display: inline-block;
}
.product-main3 .main3-right {
  float: right;
  position: relative;
  z-index: 2;
  /*width: 7.19rem;*/
  /*height: 5.03rem;*/
  width: 5.56rem;
  height: 3.89rem;
  margin-top: -0.40rem;
  background-size: 100% 100%;
  background-image: url("../assets/product-main3-bg.png");
}
/* 第四部分 */
.product-main4 {
  height: 1.98rem;
  margin-top: 1.80rem;
  background-size: 100% 100%;
  padding: 0 2.20rem;
  background-image: url("../assets/product-main4-bg.png");
}
.product-main4-ul .product-main4-li {
  display: inline-block;
  height: 2.04rem;
  margin-top: -0.57rem;
  width: 15%;
  margin-right: 2%;
}
.product-main4-ul .product-main4-li:last-child {
  margin-right: 0%;
}
.product-main4-li .main4-li-bg {
  display: inline-block;
  width: 1.47rem;
  height: 2.04rem;
  padding: 0.78rem 0.11rem 0.11rem 0.11rem;
  box-sizing: border-box;
  background-size: 100% 100%;
}
.product-main4-li .main4-li-title {
  line-height: 0.50rem;
  font-size: 0.14rem;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  font-weight: bold;
  color: #00205c;
}
.product-main4-li .main4-li-content {
  line-height: 0.20rem;
  font-size: 0.14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Source Han Sans CN-Bold, Source Han Sans CN";
  color: #565656;
}
.card1 {
  background-image: url("../assets/card1.png");
}
.card2 {
  background-image: url("../assets/card2.png");
}
.card3 {
  background-image: url("../assets/card3.png");
}
.card4 {
  background-image: url("../assets/card4.png");
}
.card5 {
  background-image: url("../assets/card5.png");
}
.card6 {
  background-image: url("../assets/card6.png");
}
/* 移动端 */
@media only screen and (max-width: 768px) {
  .product-main1 .left-main {
    height: 2.46rem;
  }
  .product-main1 .right-main {
    padding: 0 0.40rem;
    margin-right: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .top-words-1 {
    margin-top: 0.1rem;
    margin-left: 0.15rem;
  }
  .top-words-2 {
    margin-top: 0.4rem;
    margin-left: 0.15rem;
  }
  .left-images {
    padding-left: 0.20rem !important;
    padding-right: 0.20rem !important;
  }
  .product-main2 {
    padding-top: 0;
    padding: 0.40rem 0.20rem;
  }
  .product-main2 .icon-common {
    display: none !important;
  }
  .product-main2 .left-block {
    padding: 0.40rem 0.25rem;
  }
  .main2-container .title-main {
    font-size: 0.2rem;
  }
  .main2-container .title-sub {
    font-size: 0.2rem;
  }
  .main2-container .block-content {
    padding: 0.10rem 0;
  }
  .left-block .block-content {
    font-size: 0.11rem;
  }
  .right-block .block-content {
    font-size: 0.11rem;
  }
  .product-main2 .left-block .top-title-desc {
    font-size: 0.12rem;
  }
  .product-main2 .right-block {
    padding: 0.68rem 0.15rem 0.10rem 0.10rem;
    margin-left: -0.20rem;
  }
  .main-li .main-li-desc {
    line-height: 0.2rem;
  }
  .right-block .main-ul .main-li {
    margin-bottom: 0.28rem;
  }
  .right-block .main-li .main-li-title {
    margin-bottom: 0.08rem;
  }
  #product-container .product-main2 {
    background-color: rgb(237, 253, 255);
    height: auto;
  }
  .product-main3 {
    padding-left: 0.20rem;
  }
  .main3-right-container::after {
    clear: both;
    content: "";
    display: block;
  }
  .main3-li-right {
    margin-left: 0.10rem;
    max-width: 3.00rem;
    width: 2.60rem;
    line-height: 0.2rem;
  }
  .main3-right-container {
    width: 100%;
    margin-top: 0.30rem;
  }
  .product-main3 .main3-right {
    width: 3.12rem;
    height: 2.19rem;
  }
  .product-main4 {
    margin-top: 0.30rem;
    padding: 0;
    height: auto;
  }
  .product-main4-ul .product-main4-li {
    width: 30%;
    height: 1.43rem;
    margin-top: 0;
    margin-bottom: 0.20rem;
    margin-left: 2.5%;
    margin-right: 0;
    text-align: center;
  }
  .product-main4-ul .product-main4-li:nth-child(1),
  .product-main4-ul .product-main4-li:nth-child(2),
  .product-main4-ul .product-main4-li:nth-child(3) {
    margin-top: -0.30rem;
  }
  .product-main4-li .main4-li-bg {
    width: 1.04rem;
    height: 1.43rem;
    padding: 0.66rem 0.08rem 0.08rem 0.08rem;
  }
  .product-main4-li .main4-li-title {
    line-height: 0.09rem;
  }
  .product-main4-li .main4-li-content {
    font-size: 0.12rem;
    line-height: 0.16rem;
    margin-top: 0.08rem;
  }
}

/*小屏幕*/
@media only screen and (min-width: 769px) and (max-width: 992px)  {
  .product-main1 .left-main {
    height: 8.46rem;
  }
  .product-main1 .right-main {
    padding: 0.1rem 1.8rem;
    margin-right: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .product-main1 .top-words {
    width: 100%;
    height: auto;
    font-size: 0.32rem;
    line-height: 0.8rem;
  }
  .top-words-1 {
    margin-top: 0.2rem;
  }
  .product-main2 {
    margin-top: 0;
  }
  .product-main2 .icon-common {
    display: none !important;
  }
  .product-main3 {
    padding-top: 1.8rem;
  }
  .product-main3 .main3-left {
    width: 100%;
  }
  .main3-li-left {
    width: 1rem;
    line-height: 0.4rem;
  }
  .main3-li-right {
    line-height: 0.2rem;
  }
  .main3-right-container {
    width: 100%;
    margin-top: 0.30rem;
  }
  .main3-li-right {
    line-height: 0.75rem;
  }
}

/*中等屏幕*/
@media only screen and (min-width: 992px) and (max-width: 1200px)  {
  .product-main1 .top-words {
    width: 100%;
    height: auto;
    line-height: 0.25rem;
  }
}
</style>