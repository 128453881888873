import Vue from 'vue';
import Router from 'vue-router';
//自定义页面
import indexPage from './views/index';
import loginPage from './views/login';
import registPage from './views/regist';
import forgetPage from './views/forget';
// 知识问答展示详情
import knowledgePage from './components/knowledgeDetails';
import bacilliformisPage from './components/bacilliformisDetails';

// import editPassword from './views/baseMessage/baseMessage'

//安装路由到vue
Vue.use(Router)

const routes = [{
  path: '/',
  name: 'index',
  component: indexPage,
},

{
  path: '/login',
  name: 'login',
  component: loginPage
},
{
  path: '/regist',
  name: 'regist',
  component: registPage
},
{
  path: '/forget',
  name: 'forget',
  component: forgetPage
},
{
  path: '/knowledge',
  name: 'knowledge',
  component: knowledgePage
},
{
  path: '/bacilliformisDetails',
  name: 'bacilliformisDetails',
  component: bacilliformisPage
},
]

export default new Router({
  //mode: 'hash', //默认hash模式，hash模式有#；另外还有一种history模式，没有#符号
  mode: 'hash',
  routes
})