 
<template>
  <div>
    <div class="contiaer-app">
      <div class="bigheader">
        <div class="contiaer-app-bigtitle">圣视尔佳APP</div>
        <div class="contiaer-app-littletitle">下载免费训练</div>
        <div class="button-common">
          <a class="erwei">
            <i class="download-common mobile-download">
              <div class="mobile-container">
                <i class="qr-code"></i>
                <span>微信扫一扫 或 截屏长按二维码</span>
              </div>
            </i>
          </a>
          <a
            v-if="clientWidth > 1400"
            href="https://oss-kds-eyes.com/kds_win_install.exe"
            class="erwei"
          >
            <i class="download-common pc-download"></i>
          </a>
          <a class="download-common huawenTu"></a>
        </div>
      </div>
      <div class="boxLiOl">
        <div class="headertype">
          <p class="Suitable">Suitable for what types of amblyopia</p>
          <p class="subtitletype">适合哪些类型的弱视</p>
        </div>
        <div class="pictureboxs">
          <div class="picture1box">
            <div class="picture1"></div>
            <div class="leftbox">
              <div class="innerbox" v-for="item in leftcontent" :key="item.id">
                <img
                  style="width: 0.12rem; height: 0.12rem; margin-top: 0.05rem"
                  src="../assets/star.png"
                  alt=""
                />
                <div class="suplayout">
                  <p
                    style="font-size: 0.14rem; font-weight: bold"
                    v-html="item.title"
                  ></p>
                  <p style="font-size: 0.14rem" v-html="item.suptitle"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="picture2box">
            <div class="picture2"></div>
            <div class="centerbox">
              <div
                class="centerinnerbox"
                v-for="item in centercontent"
                :key="item.id"
              >
                <img
                  style="width: 0.12rem; height: 0.12rem; margin-top: 0.05rem"
                  src="../assets/star.png"
                  alt=""
                />
                <p
                  style="
                    font-size: 0.14rem;
                    font-weight: bold;
                    margin-left: 0.1rem;
                  "
                  v-html="item.title"
                ></p>
                <!-- <p style="font-size: 0.14rem" v-html="item.suptitle"></p> -->
              </div>
              <p
                style="font-size: 0.14rem; margin-top: 0.4rem"
                class="fontnice"
                v-html="'注意配合遮盖，并根据视力变化调整弱视训练时间'"
              ></p>
            </div>
          </div>
          <div class="picture3box">
            <div class="picture3"></div>
            <div class="rightbox">
              <div class="innerbox" v-for="item in rightcontent" :key="item.id">
                <img
                  style="width: 0.12rem; height: 0.12rem; margin-top: 0.05rem"
                  src="../assets/star.png"
                  alt=""
                />
                <div class="suplayout">
                  <p style="font-size: 0.14rem" v-html="item.title"></p>
                  <p style="font-size: 0.14rem" v-html="item.suptitle"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zeSeHen">
        <p
          class="dftype"
          v-html="'不同类型弱视训练方法不同<br/>圣视尔佳针对弱视类型个性化定制'"
        ></p>
        <div class="sliceimg"></div>
      </div>
      <div class="presention">
        <p class="Suitable">Product Presention</p>
        <p class="subtitle">产品介绍</p>
        <div
          class="presentioncontent"
          v-html="
            '圣视尔佳弱视训练HD，是一款专为儿童弱视训练研发，产品基于CAM弱视训练理念，通过多媒体的弱视<br/>训练系统视觉交互技术，结合脑视觉、眼科及儿童心理等设计理论……'
          "
        ></div>
        <img
          style="width: 0.14rem; height: 0.14rem; margin-top: 0.24rem"
          src="../assets/icon-arrow.png"
          alt=""
        />
      </div>
      <div class="knowspresention">
        <img
          v-if="clientWidth > 1400"
          class="bg1"
          src="../assets/blue-slice.png"
          alt=""
        />
        <img
          v-if="clientWidth > 1400"
          class="bg2"
          src="../assets/yellow-star.png"
          alt=""
        />
        <img
          class="bg3"
          style="width: 0.98rem; height: 0.72rem"
          v-if="clientWidth > 1400"
          src="../assets/yellow-E.png"
          alt=""
        />
        <div class="columns">
          <div>
            <p class="Suitable">Product Presention</p>
            <p class="subtitle">知识问答</p>
          </div>
        </div>
        <div class="scondecolumns" v-for="item in columns" :key="item.id">
          <div>
            <p class="bigtitle" v-html="item.title"></p>
<!--            <p class="littletitle" v-html="item.content"></p>-->
          </div>
          <div class="qa-more">
            <img
                src="../assets/icon-add.png"
                @click="jumppages"
                alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="boxtip" @click="boxTip">立即匹配视力类型</div>
    <bacilliformis class="form" />
  </div>
</template>

<script>
import bacilliformis from "./bacilliformis.vue";
import EventBus from "../../utils/Eventbus";
export default {
  name: "amblyopiaTraining",
  data() {
    return {
      clientWidth: document.body.clientWidth,
      leftcontent: [
        {
          id: 0,
          title: "近视型弱视",
          suptitle: "做弱视训练时，<br/>要注意近视度数的增加",
        },
        {
          id: 1,
          title: "散光型弱视",
          suptitle: "做弱视训练时，<br/>要注意近视度数的增加",
        },
        {
          id: 2,
          title: "远视型弱视",
          suptitle: "要增加弱视训练力度，<br/>改善远视度数",
        },
      ],
      centercontent: [
        { id: 0, title: "近视型弱视" },
        { id: 1, title: "散光型弱视" },
        { id: 2, title: "远视型弱视" },
      ],
      rightcontent: [
        { id: 0, title: "先天性斜视弱视" },
        { id: 1, title: "屈光不正性斜视弱视" },
        { id: 2, title: "换过晶体弱视" },
        { id: 3, title: "旁中心注视" },
        { id: 4, title: "玻璃体浑浊" },
        { id: 5, title: "视网膜黏连" },
      ],
      columns: [
        // {id:0,title:'什么是弱视？',content:'通俗一点来讲就是在眼底无器质性病变的情况下，配戴眼镜也无法矫正到正常年……'},
        // {id:1,title:'什么是远视？',content:'远视是指平行光线进入眼睛后成像在视网膜后面，造成视网膜成像不清。在一定范围内远视力<br/>和近视力（看近的视力）…'},
        // {id:2,title:'什么是近视？',content:'近视是指平行光线进入眼睛后成像在视网膜前面，造成视网膜成像不清。远视力明显下降，但<br/>近视力（看近的视力）…'}
      ],
    };
  },
  components: {
    bacilliformis,
  },
  created() {
    this.getanswertemp();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.clientWidth = window.screenWidth;
      })();
    };
  },

  methods: {
    getanswertemp() {
      const obj = {
        sourceChannel: "guanwang",
        sourcePlatform: "web",
        clientPlatform: "web",
        dataSource: "guanwang",
      };
      this.$axios({
        url: "app/v1.0/guanwang/listQuestionAnswer", //请求地址
        method: "post", //请求方法
        data: obj,
      }).then((res) => {
        this.columns = res.data.data.questionAnswerList;
      });
    },
    jumppages() {
      EventBus.$emit("jumpages", 3);
    },
    boxTip() {
      this.$router.push({ path: "/bacilliformisDetails" });
    },
  },
};
</script>

<style>
.contiaer-app {
  /* height: 9.51rem; */
  width: 99vw;
}

.contiaer-app-bigtitle {
  height: 0.26rem;
  width: 1.58rem;
  margin-top: 0.8rem;
  font-size: 0.26rem;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 600;
  color: #00205c;
  line-height: 0.26rem;
}

.contiaer-app-littletitle {
  height: 0.22rem;
  width: 1.57rem;
  margin-top: 0.1rem;
  font-size: 0.22rem;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #00205c;
  line-height: 0.22rem;
}

.boxtip {
  display: none;
}

.bigheader {
  margin-left: 2.22rem;
}

.button-common {
  position: relative;
}

.button-common .pc-download {
  width: 1.19rem;
  margin-left: 0.18rem;
  background-image: url("../assets/pc-download.png");
}

.button-common .download-common {
  display: inline-block;
  height: 0.42rem;
  background-size: 100% 100%;
}

.button-common .mobile-download {
  position: relative;
  width: 1.83rem;
  background-image: url("../assets/mobile-download.png");
}

.button-common .huawenTu {
  position: relative;
  width: 2.78rem;
  height: 0.45rem;
  margin-left: 1.32rem;
  background-image: url("../assets/pic_line_4.png");
}

.boxLiOl {
  height: 6rem;
  width: 7.14rem;
  margin-top: 1rem;
  margin-left: 2.22rem;
  background-size: 100% 100%;
}

.subtitle {
}

.subtitletype {
  font-size: 0.4rem;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #00205c;
}

.pictureboxs {
  display: flex;
  justify-content: space-around;
  margin-top: 0.3rem;
}

.picture1box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picture2box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picture1 {
  width: 2.16rem;
  height: 1.5rem;
  background: url("../assets/green.png") no-repeat center center;
  background-size: 100% 100%;
}

.picture2 {
  width: 2.16rem;
  height: 1.5rem;
  background: url("../assets/popu.png") no-repeat center center;
  background-size: 100% 100%;
}

.picture3 {
  width: 2.16rem;
  height: 1.5rem;
  background: url("../assets/yellow.png") no-repeat center center;
  background-size: 100% 100%;
}

.fontnice {
  width: 1.68rem;
}

.maincontent {
  display: flex;
  justify-content: space-around;
}

.centerbox {
  box-sizing: border-box;
  padding-top: 0.4rem;
}

.innerbox {
  display: flex;
  margin-top: 0.28rem;
}

.suplayout {
  margin-left: 0.1rem;
}

.centerinnerbox {
  display: flex;
}

.Suitable {
  font-size: 0.16rem;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #00a887;
}

.zeSeHen {
  height: 2.26rem;
  width: 100%;
  background-color: #998ceb;
  /* display: flex; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 0.67rem 2.22rem;
  position: relative;
}

.dftype {
  font-size: 0.38rem;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.sliceimg {
  width: 2.21rem;
  height: 2.21rem;
  background: url("../assets/slice.png") no-repeat center center;
  background-size: 100% 100%;
  margin-left: 0.5rem;
  position: absolute;
  top: 0;
  right: 3.7rem;
}

.presention {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.14rem;
  margin-top: 0.96rem;
}

.presentioncontent {
  font-size: 0.16rem;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #565656;
  margin-top: 0.32rem;
}

.knowspresention {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.8rem;
  margin-top: 1.01rem;
  position: relative;
}

.bg1 {
  position: absolute;
  right: 1.81rem;
  bottom: 3.93rem;
}

.bg2 {
  position: absolute;
  left: 2.32rem;
}

.bg3 {
  position: absolute;
  right: 4rem;
}

.columns {
  box-sizing: border-box;
  padding-bottom: 0.4rem;
  width: 7.5rem;
  border-bottom: 0.01rem dashed #5189e9;
  display: flex;
  justify-content: center;
}

.littletitle {
  font-size: 0.16rem;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #565656;
  margin-top: 0.18rem;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: none;
}

.bigtitle {
  font-size: 0.22rem;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #00205c;
}

.scondecolumns {
  box-sizing: border-box;
  padding: 0.24rem 0;
  width: 7.5rem;
  border-bottom: 1px dashed #5189e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qa-more {
  display: flex;
  justify-content: center;

}
.qa-more img {
  width: 0.32rem;
  height: 100%
}

/* 移动端 */
@media only screen and (max-width: 768px) {
  .footer-container {
    /* position: fixed; */
    bottom: 0;
  }

  .form {
    display: none;
  }

  .training-header-title {
    margin-left: 1.97rem;
    margin-left: 0.2rem;
  }

  .subaraining-title {
    font-size: 0.12rem;
  }

  .training-header-title .main-title {
    font-size: 0.24rem;
    font-weight: normal;
  }

  .subtitletype {
    font-size: 0.35rem;
  }

  .training-header-title {
    margin-top: 0.6rem;
  }

  .subtitle {
    font-size: 0.36rem;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #00205c;
  }

  .contiaer-app {
    width: 3.75rem;
    margin-left: 0rem;
    overflow: hidden;
  }

  .button-common .huawenTu {
    right: -0.8rem;
    top: -0.2rem;
  }

  .boxLiOl {
    margin-left: 0;
    /* margin: auto; */
    margin-top: 0.96rem;
    box-sizing: border-box;
    /* padding: 0 0.1rem; */
    height: 7rem;
  }

  .headertype {
    margin-left: 0.2rem;
  }

  .bigheader {
    margin-left: 0.2rem;
  }

  .pictureboxs {
    width: 3.75rem;
    display: flex;
    flex-wrap: wrap;
  }

  .picture1 {
    width: 1.58rem;
    height: 1.3rem;
  }

  .centerbox {
    padding-top: 0.1rem;
  }

  .picture2 {
    width: 1.58rem;
    height: 1.3rem;
  }

  .picture3 {
    width: 1.58rem;
    height: 1.3rem;
    margin-right: 0.28rem;
  }

  .picture3box {
    display: flex;
    align-items: center;
    margin-top: 0.4rem;
  }

  .innerbox {
    margin-top: 0.1rem;
  }

  .zeSeHen {
    width: 3.75rem;
  }

  .zeSeHen {
    width: 3.75rem;
    height: 1.68rem;
    padding: 0.57rem 0.55rem 0.57rem 0.2rem;
    position: relative;
    overflow: hidden;
  }

  .dftype {
    font-size: 0.2rem;
  }

  .sliceimg {
    position: absolute;
    top: 0;
    right: -0.9rem;
    width: 1.58rem;
    height: 1.58rem;
  }

  .presention {
    width: 3.75rem;
  }

  .fontnice {
    width: 1.23rem;
  }

  .presentioncontent {
    box-sizing: border-box;
    padding: 0 0.15rem;
  }

  .scondecolumns {
    box-sizing: border-box;
    padding: 0.15rem 0.15rem;
    width: 3.33rem;
  }

  .columns {
    width: 3.33rem;
  }

  .boxtip {
    position: absolute;
    top: 0.6rem;
    right: 0;
    background: url("@/assets/boxtip.png") no-repeat center center;
    background-size: 100% 100%;
    width: 1.67rem;
    height: 0.58rem;
    font-size: 0.12rem;
    color: #ffffff;
    line-height: 0.48rem;
    font-weight: bold;
    text-align: center;
    display: block;
  }

  .button-common {
    display: flex;
    flex-direction: column;
  }

  .erwei {
    height: 0.56rem;
  }
}
</style>